// Librerias.
import React from "react";
import { Container, Card } from "react-bootstrap";

// Assets.
import {
  IconForkDarkSvg,
  IconGearDarkSvg,
  IconHandDarkSvg,
  IconKeyDarkSvg,
  IconRightDarkSvg,
  IconRightWhiteSvg,
  ImgDoLoop,
} from "./Assets";

const CardDo = ({ to, title, description, icon, classCard }) => {
  return (
    <a href={to} className="text-decoration-none">
      <Card style={{ width: "15.875rem" }} className={classCard}>
        <Card.Body className="p-0">
          {icon}
          <h3>{title}</h3>
          <p>{description}</p>
          <div className="d-flex justify-content-end">
            <IconRightDarkSvg />
          </div>
        </Card.Body>
      </Card>
    </a>
  );
};

const CardCfdis = ({ classCard }) => {
  return (
    <CardDo
      to="./cfdis"
      title={"Recibo de Nóminas"}
      description={"certificación (cfdi), personalización y envío por e-mail"}
      icon={<IconHandDarkSvg />}
      classCard={`card-cfdis ${classCard}`}
    />
  );
};

const CardMyCompany = ({ classCard }) => {
  return (
    <CardDo
      to="./my-company"
      title={"My[Company]®"}
      description={"Servicio de atención personalizada a empleados, vía web"}
      icon={<IconKeyDarkSvg />}
      classCard={`card-mycompany ${classCard}`}
    />
  );
};

const CardDicoma = ({ classCard }) => {
  return (
    <CardDo
      to="./dicoma"
      title={"DICOMA®"}
      description={"Aplicación web para registro y gestión de consumos"}
      icon={<IconForkDarkSvg />}
      classCard={`card-dicoma ${classCard}`}
    />
  );
};

const CardSoftware = ({ classCard }) => {
  return (
    <CardDo
      to="./software"
      title={"Desarrollo de Software a la medida"}
      description={""}
      icon={<IconGearDarkSvg />}
      classCard={`card-gear card-software ${classCard}`}
    />
  );
};

const Do = () => {
  return (
    <section className="home_do">
      <Container>
        <h2>Lo que hacemos...</h2>
        <div className="grid-do">
          <a
            href="./payroll-maquila"
            className="home_do__imgdo me-3 card-payroll"
          >
            <div class="imgbox"></div>
            <ImgDoLoop className="loop--img" />
            <p>
              <span>Procesamiento de nóminas de Alto Rendimiento</span> que se
              entiende fácilmente con RRHH y Finanzas
            </p>
            <div className="text-end">
              <IconRightWhiteSvg />
            </div>
          </a>

          <CardCfdis />
          <CardMyCompany />
          <CardDicoma />
          <CardSoftware />
        </div>
      </Container>
    </section>
  );
};

export default Do;
