// Librerias.
import React from "react";
// Components Base.
import { Footer, Contact } from "../components-base";

// Components My Company.
import Hero from "./Hero";
import Welcome from "./Welcome";
import Accessibility from "./Accessibility";
import Users from "./Users";
import Rules from "./Rules";


const PageMyCompany = () => {
  return (
    <>
      <Hero />
      <Welcome />
      <Rules />
      <Accessibility />
      <Users />
      <Contact title="¿Desea mejorar la calidad del servicio interno que RRHH ofrece a sus empleados?" />
      <Footer />
    </>
  );
};

export default PageMyCompany;
