// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Card } from "react-bootstrap";

// Assets.
import {
  IllustCommunicationSvg,
  IllustExperienceSvg,
  IllustPerspectiveSvg,
} from "./Assets";

const CardService = ({ icon, title, description }) => {
  return (
    <Card
      style={{ width: "18.75rem", height: "27.5rem" }}
      className="mb-lg-0 me-lg-0 me-md-5 me-0 me-sm-0 mb-5"
    >
      <Card.Body className="d-flex flex-wrap justify-content-end align-content-end">
        {icon}
        <h3>{title}</h3>
        {description}
      </Card.Body>
    </Card>
  );
};

const Why = () => {
  return (
    <section className="home_why">
      <Container>
        <h2>Por qué lo hacemos…</h2>
        <div className="d-flex justify-content-lg-between justify-content-center flex-xl-nowrap flex-wrap ">
          <CardService
            description={
              <p>
                <span>Mejor experiencia de trabajo </span>para las áreas de
                nómina, finanzas y RRHH
              </p>
            }
            icon={<IllustExperienceSvg />}
          />
          <CardService
            description={
              <p>
                <span>Comunicación directa y clara </span>con todos los
                empleados
              </p>
            }
            icon={<IllustCommunicationSvg />}
          />
          <CardService
            description={
              <p>
                <span>Amplia perspectiva </span>para los líderes de sus equipos
                y la dirección
              </p>
            }
            icon={<IllustPerspectiveSvg />}
          />
        </div>
      </Container>
    </section>
  );
};

export default Why;
