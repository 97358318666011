// Librerias.
import React from "react";

// Images.
import designThinkingImg from "./assets/img/design-thinking.svg";
import designThinkingPhoneImg from "./assets/img/design-thinking-phone.svg";
import loopImg from "./assets/img/loop.svg";
import optimizationImg from "./assets/img/optimization.svg";
import personImg from "./assets/img/person.png";
import watchImg from "./assets/img/watch.png";
import womanImg from "./assets/img/woman.png";

export const DesignThinkingImg = ({ className }) => {
  return (
    <img
      src={designThinkingImg}
      alt="design thinking"
      className={className}
      width="1080"
      height="686"
    />
  );
};
export const DesignThinkingPhoneImg = ({ className }) => {
  return (
    <img
      src={designThinkingPhoneImg}
      alt="design thinking"
      className={className}
      width="300"
      height="1080"
    />
  );
};
export const LoopImg = ({ className }) => {
  return (
    <img
      src={loopImg}
      alt="design thinking"
      className={className}
      width="446"
      height="208"
    />
  );
};
export const OptimizationImg = ({ className }) => {
  return (
    <img
      src={optimizationImg}
      alt="design thinking"
      className={className}
      width="290"
      height="273"
    />
  );
};
export const PersonImg = ({ className }) => {
  return (
    <img
      src={personImg}
      alt="person"
      className={className}
      width={280}
      height={280}
    />
  );
};
export const WatchImg = ({ className }) => {
  return (
    <img
      src={watchImg}
      alt="person"
      className={className}
      width={280}
      height={280}
    />
  );
};
export const WomanImg = ({ className }) => {
  return (
    <img
      src={womanImg}
      alt="person"
      className={className}
      width={280}
      height={280}
    />
  );
};
