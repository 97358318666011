// Librerias.
import React from "react";

// Svgs.
import iconSLoopSvg from "./assets/svg/icon-s-loop.svg";
import iconHandSvg from "./assets/svg/icon-s-hand.svg";
import iconKeySvg from "./assets/svg/icon-s-key.svg";
import iconForkSvg from "./assets/svg/icon-s-fork.svg";
import iconGearSvg from "./assets/svg/icon-s-gear.svg";
import iconForkDarkSvg from "./assets/svg/icon-fork-dark.svg";
import iconGearDarkSvg from "./assets/svg/icon-gear-dark.svg";
import iconHandDarkSvg from "./assets/svg/icon-hand-dark.svg";
import iconKeyDarkSvg from "./assets/svg/icon-key-dark.svg";
import iconRightDarkSvg from "./assets/svg/icon-right-dark.svg";
import iconRigthWhiteSvg from "./assets/svg/icon-right-white.svg";
import iconArrowSvg from "./assets/svg/icon-arrow.svg";
import iconMexicoSvg from "./assets/svg/icon-mexico.svg";
import illustCommunicationSvg from "./assets/svg/illust-communication.svg";
import illustExperienceSvg from "./assets/svg/illust-experience.svg";
import illustPerspectiveSvg from "./assets/svg/illust-perspective.svg";
import imgDoLoop from "./assets/img/img-do-loop.png";

// Logos.
import aeromexicoLogo from "./assets/logos-companies/aeromexico.svg";
import bonafont from "./assets/logos-companies/bonafont.svg";
import circlepurple from "./assets/logos-companies/circlepurple.svg";
import condumex from "./assets/logos-companies/condumex.svg";
import danone from "./assets/logos-companies/danone.svg";
import exxonmobil from "./assets/logos-companies/exxonmobil.svg";
import gillete from "./assets/logos-companies/gillete.svg";
import glaxowellcome from "./assets/logos-companies/glaxowellcome.svg";
import ici from "./assets/logos-companies/ici.svg";
import janseencilag from "./assets/logos-companies/janseencilag.svg";
import kraft from "./assets/logos-companies/kraft.svg";
import pemex from "./assets/logos-companies/pemex.svg";
import pfizer from "./assets/logos-companies/pfizer.svg";
import pilgrims from "./assets/logos-companies/pilgrims.svg";
import toyota from "./assets/logos-companies/toyota.svg";
import univision from "./assets/logos-companies/univision.svg";

// Img's
import howBg from "./assets/img/how-people.jpg";
import howBgMobile from "./assets/img/how-people-mobile.jpg";

export const IconSLoopSvg = () => {
  return <img src={iconSLoopSvg} alt="Loop Icon" />;
};
export const IconHandSvg = () => {
  return <img src={iconHandSvg} alt="Hand Icon" />;
};
export const IconKeySvg = () => {
  return <img src={iconKeySvg} alt="Key Icon" />;
};
export const IconForkSvg = () => {
  return <img src={iconForkSvg} alt="Fork Icon" />;
};

export const IconGearSvg = () => {
  return <img src={iconGearSvg} alt="Gear Icon" />;
};
export const IconForkDarkSvg = () => {
  return <img src={iconForkDarkSvg} alt="Fork Dark Icon" />;
};
export const IconGearDarkSvg = () => {
  return <img src={iconGearDarkSvg} alt="Gear Dark Icon" />;
};
export const IconHandDarkSvg = () => {
  return <img src={iconHandDarkSvg} alt="Hand Dark Icon" />;
};
export const IconKeyDarkSvg = () => {
  return <img src={iconKeyDarkSvg} alt="Key dark Icon" />;
};
export const IconRightDarkSvg = () => {
  return <img src={iconRightDarkSvg} alt="Rigth dark Icon" />;
};
export const IconRightWhiteSvg = () => {
  return <img src={iconRigthWhiteSvg} alt="White dark Icon" />;
};

export const IconArrowSvg = () => {
  return <img src={iconArrowSvg} alt="Arrow Icon" />;
};

export const IconMexicoSvg = () => {
  return <img src={iconMexicoSvg} alt="Mexico Icon" />;
};

export const IllustCommunicationSvg = () => {
  return (
    <img
      src={illustCommunicationSvg}
      width="280"
      height="238"
      alt="Illustration Communication"
      className="card__img"
    />
  );
};

export const IllustExperienceSvg = () => {
  return (
    <img
      src={illustExperienceSvg}
      width="280"
      height="238"
      alt="Illustration Experience"
      className="card__img"
    />
  );
};

export const IllustPerspectiveSvg = () => {
  return (
    <img
      src={illustPerspectiveSvg}
      width="280"
      height="238"
      alt="Illustration Perspective"
      className="card__img"
    />
  );
};
export const ImgDoLoop = ({ className }) => {
  return (
    <img
      src={imgDoLoop}
      alt="lo que hacemos ..."
      width="101"
      height="76"
      className={className}
    />
  );
};

// Logos de compañias.
export const LogoAeromexico = ({ className }) => {
  return (
    <img
      src={aeromexicoLogo}
      alt="Logo Aeromexico"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoBonfont = ({ className }) => {
  return (
    <img
      src={bonafont}
      alt="Logo Bonfont"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoCirclePurple = ({ className }) => {
  return (
    <img
      src={circlepurple}
      alt="Logo CirclePurple"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoCondumex = ({ className }) => {
  return (
    <img
      src={condumex}
      alt="Logo Condumex"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoDanone = ({ className }) => {
  return (
    <img
      src={danone}
      alt="Logo Danone"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoExxonMobil = ({ className }) => {
  return (
    <img
      src={exxonmobil}
      alt="Logo ExxonMobil"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoGillete = ({ className }) => {
  return (
    <img
      src={gillete}
      alt="Logo Gillete"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoGlaxoWellcome = ({ className }) => {
  return (
    <img
      src={glaxowellcome}
      alt="Logo GlaxoWellcome"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoIci = ({ className }) => {
  return (
    <img
      src={ici}
      alt="Logo Ici"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoJanseencilag = ({ className }) => {
  return (
    <img
      src={janseencilag}
      alt="Logo Janseencilag"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoKraft = ({ className }) => {
  return (
    <img
      src={kraft}
      alt="Logo Kraft"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoPemex = ({ className }) => {
  return (
    <img
      src={pemex}
      alt="Logo Pemex"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoPfizer = ({ className }) => {
  return (
    <img
      src={pfizer}
      alt="Logo Pfizer"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoPilgrims = ({ className }) => {
  return (
    <img
      src={pilgrims}
      alt="Logo Pilgrims"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoToyota = ({ className }) => {
  return (
    <img
      src={toyota}
      alt="Logo Toyota"
      width={140}
      height={140}
      className={className}
    />
  );
};
export const LogoUnivision = ({ className }) => {
  return (
    <img
      src={univision}
      alt="Logo Univision"
      width={140}
      height={140}
      className={className}
    />
  );
};

// Img's
export const HowBg = ({ className }) => {
  return <img src={howBg} alt="How People" className={className} />;
};
export const HowBgMobile = ({ className }) => {
  return (
    <img src={howBgMobile} alt="How People Mobile" className={className} />
  );
};
