// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import reportGeneratorImg from "./assets/img/report-generator.jpg";

// Components Payroll Maquila.
import { ReturnToServices } from "../components-base";
const ReportGenerator = () => {
  return (
    <section className="payroll_maquila_report-generator">
      <Container>
        <h3 className="mb-4">Generador de Reportes</h3>
        <p className="mb-5">
          Los miembros de Nóminas / RRHH / Finanzas y/o cualquier otro usuario
          autorizado podrán extraer fácilmente cualquier información que
          necesiten de nuestro sistema de nómina, accediendo al módulo Generador
          de Reportes dentro de nuestra app iNTEGRITY.
        </p>
        <img
          src={reportGeneratorImg}
          alt="report generator"
          className="mb-5 img-fluid"
          width={1080}
          heigth={584}
        />
        <ReturnToServices />
      </Container>
    </section>
  );
};
export default ReportGenerator;
