// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import accessibility from "./assets/img/accessibility.jpg";
import line from "./assets/img/line.svg";

const Accessibility = () => {
  return (
    <section className="my_company_accessibility">
      <Container>
        <div>
          <h2>
            Usabilidad,
            <br />
            nuestra prioridad.
          </h2>
          <div className="img-line">
            <img src={line} alt="linea" />
          </div>
        </div>
        <p className="description">
          Esta aplicación, como todas las que desarrollamos, busca distinguirse
          por la manera fácil, rápida y agradable en la que los usuarios pueden
          completar el proceso para el cual la están usando. Tomamos en cuenta
          al usuario, el contexto y el problema a resolver para ofrecer la mejor
          experiencia de uso, sin importar el dispositivo desde donde se acceda.
        </p>
        <div>
          <img
            src={accessibility}
            alt="my company products"
            className="img-fluid img-devices"
          />
        </div>
      </Container>
    </section>
  );
};
export default Accessibility;
