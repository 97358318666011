import React from "react";
import { Container } from "react-bootstrap";
//Asset.
import checkIn from "./assets/img/circle-checkin.svg";
import petitionSvg from "./assets/check-in/petition.svg";
import petitionMobileSvg from "./assets/check-in/petition-mobile.svg";
import recordSvg from "./assets/check-in/record.svg";
import recordMobileSvg from "./assets/check-in/record-mobile.svg";
import responseSvg from "./assets/check-in/response.svg";
import responseMobileSvg from "./assets/check-in/response-mobile.svg";
import validationSvg from "./assets/check-in/validation.svg";
import validationMobileSvg from "./assets/check-in/validation-mobile.svg";

const Checkin = () => {
  return (
    <section className="dicoma_checkin">
      <Container>
        <div className="wrapper">
          <h1 className="title">Check-in</h1>
          <p className="description">
            En un dispositivo con conexión a internet montado en la fila de la
            cafetería, los empleado, proveedores o invitados, pueden solicitar
            sus comidas con distintos métodos de acceso: credencial, no.
            empleado o código QR. Y, en la pantalla del dispositivo, el empleado
            a cargo de servir, podrá visualizar la respuesta.
          </p>
          <img src={checkIn} alt="" className="img-fluid illust" />
        </div>
        <div className="cards">
          <div className="card">
            <img
              src={petitionSvg}
              alt="Petition"
              className="img-fluid card--img"
            />
            <img
              src={petitionMobileSvg}
              alt="Petition"
              className="img-fluid card--img-mobile"
            />
            <h3 className="card--title">1. Petición</h3>
            <p className="card--description">
              Con tarjeta de proximidad RFID, ingresando el No. empleado o
              mostrando un código QR personal.
            </p>
          </div>
          <div className="card">
            <img
              src={validationSvg}
              alt="Validation"
              className="img-fluid card--img"
            />
            <img
              src={validationMobileSvg}
              alt="Validation"
              className="img-fluid card--img-mobile"
            />
            <h3 className="card--title">2. Validación</h3>
            <p className="card--description">
              El sistema valida en milisegundos estatus del empleado, turno y
              hora de la petición, entre otros…
            </p>
          </div>
          <div className="card">
            <img
              src={responseSvg}
              alt="Response"
              className="img-fluid card--img"
            />
            <img
              src={responseMobileSvg}
              alt="Response"
              className="img-fluid card--img-mobile"
            />
            <h3 className="card--title">3. Respuesta</h3>
            <p className="card--description">
              Conforme a las políticas de la empresa, muestra la respuesta
              asignada a la petición.
            </p>
          </div>
          <div className="card">
            <img src={recordSvg} alt="Record" className="img-fluid card--img" />
            <img
              src={recordMobileSvg}
              alt="Record"
              className="img-fluid card--img-mobile"
            />
            <h3 className="card--title">4. Registro</h3>
            <p className="card--description">
              Sea cual sea la respuesta, el registro se envía y almacena en los
              reportes de consumo.
            </p>
          </div>
        </div>

        <div className="blue-line"></div>

        <div className="data">
          <div className="its-fast">
            <h3 className="its-fast__title">¡Es muy rápido!</h3>
            <p className="its-fast__quote">
              El tiempo de comida de los empleados es muy valioso y no se puede
              desperdiciar haciendo largas filas. ¡Buscamos que puedan comer
              cómodos y a su ritmo!
            </p>
          </div>
          <div className="yellow-rectangle">
            <div>
              Tiempo de <br />
              respuesta:
            </div>
            <div className="yellow-rectangle__seconds">
              <span>0.780</span> segundos
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Checkin;
