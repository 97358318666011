// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import integrationImg from "./assets/img/integration.png";
import line from "./assets/img/line.svg";
const Rules = () => {
  return (
    <section className="integrity_integration">
      <Container>
        <h2>
          Integración completa de su <br />
          estructura de cálculo
        </h2>

        <div className="img-line">
          <img src={line} alt="linea" />
        </div>
        <p className="description">
          iNTEGRITY es la nueva versión de nuestro anterior sistema de nóminas.
          Es un concepto más moderno basado en una aplicación web: fácil de
          usar, con una buena experiencia de usuario y básicamente se integra en
          un solo sistema en la nube, de ahí el nombre iNTEGRITY, además se
          comunica con <span>DiCOMA</span>, nuestra aplicación de Cafetería y
          con nuestra aplicación de auto-servicio al empleado My
          <span>[Company]</span>.
        </p>

        <img src={integrationImg} alt="Integration" className="img-fluid"></img>
      </Container>
    </section>
  );
};
export default Rules;
