// Librerias.
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

// Components Base.
import { GradientBg, ReturnToServices } from "../components-base";

// Components Payrroll Maquila.
import { TitleSpecialProcesses } from "./Titles";

// Assets.
import {
  AccountingSvg,
  LogoImssSvg,
  LogoSatSvg,
  SettlementsPayrollSvg,
} from "./assets";
import accountingBgImg from "./assets/special-processes/accounting-bg.png";
import imssBgImg from "./assets/special-processes/imss-bg.png";
import satBgImg from "./assets/special-processes/sat-bg.png";
import settlementsBgImg from "./assets/special-processes/settlements-bg.png";

const BgCard = styled.div`
  background: #521798 no-repeat left center
    url(${(props) => (props.bgCard ? props.bgCard : props.bgCard)});
`;

const CardSpecialProcesses = ({ icon, title, children, bgCard }) => {
  return (
    <div className="card">
      <BgCard bgCard={bgCard} className="card-header">
        <div className="card-header__icon">{icon}</div>
        <div className="card-header__title">{title}</div>
      </BgCard>
      <div className="card-body">{children}</div>
    </div>
  );
}; 

const SpecialProcess = () => {
  return (
    <section className="payroll_maquila_special-processes">
      <GradientBg
        className="gradient"
        direction="top left"
        gradientFrom="rgba(245, 245, 245, 1) 5%"
      >
        <Container>
          <TitleSpecialProcesses />
          <div className="cards margin">
            <CardSpecialProcesses
              icon={<SettlementsPayrollSvg />}
              title="Nómina de Finiquitos"
              bgCard={settlementsBgImg}
            >
              <div>
                Mensualmente o al final de cada período de nómina (esto depende
                de las especificaciones definidas en la fase de implementación),
                calcularemos una nómina especial que proporciona un desglose
                detallado de todos los pagos de indemnizaciones y liquidaciones,
                facilitando el timbrado de los recibos.
              </div>
            </CardSpecialProcesses>

            <CardSpecialProcesses
              icon={<LogoSatSvg />}
              title="Pago de Impuestos"
              bgCard={satBgImg}
            >
              <div>
                Preparación y envío de toda la información requerida sobre el
                pago de impuestos:
              </div>
              <div>
                <div>+ ISR</div>
                <li>- Impuesto retenido</li>
                <li>- Subsidio (a impuesto)</li>
                <li>-Subsidio (a empleado)</li>
              </div>
              <ul>
                <div>+ ISN</div>
                <li>- Bases para el pago</li>
                <li>- Impuesto a pagar</li>
                <li>- Número de trabajadores</li>
                <li>- Validación de CFDI’s vs. SAT</li>
              </ul>
            </CardSpecialProcesses>

            <CardSpecialProcesses
              icon={<LogoImssSvg />}
              title="Seguro Social"
              bgCard={imssBgImg}
            >
              <ul>
                <div>+ Movimientos de afiliación</div>
                <li>· Altas</li>
                <li>· Bajas</li>
                <li>· Modificaciones salariales</li>
              </ul>
              <div>- Determinación de variables (bimestral)</div>
              <div>- Determinación mensual de cuotas obrero-patronales </div>
              <div>-Elaboración mensual de archivos para auditoría</div>
            </CardSpecialProcesses>

            <CardSpecialProcesses
              icon={<AccountingSvg />}
              title="Aspectos Contables"
              bgCard={accountingBgImg}
            >
              <div>
                Elaboración y transferencia de todas las pólizas de Nómina, de
                acuerdo a las necesidades del sistema contable de nuestros
                clientes:
              </div>
              <br></br>
              <ul>
                <li>- Nómina</li>
                <li>- Finiquitos</li>
                <li>- Provisiones</li>
              </ul>
            </CardSpecialProcesses>
          </div>
          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default SpecialProcess;
