// Librerias.
import React from "react";
// Components Base.
import { Footer, Contact } from "../components-base";

// Components Payroll Maquila.
import Hero from "./Hero";
import PhasesServices from "./PhasesServices";
import Usability from "./Usability";
import Strategy from "./Strategy";
import Architecture from "./Architecture";
import Ux from "./Ux";
import Ui from "./Ui";
import Software from "./Software";
import Production from "./Production";

const PageSoftware = () => {
  return (
    <div>
      <Hero />
      <PhasesServices />
      <Usability />
      <Strategy />
      <Architecture />
      <Ux />
      <Ui />
      <Software />
      <Production />
      <Contact title="¿Desea automatizar procesos manuales en la gestión administrativa de su capital humano?" />
      <Footer />
    </div>
  );
};

export default PageSoftware;
