// Librerias.
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Components Base.
import { GradientBg} from "../components-base";

// Components Payrroll Maquila.
import { TitleCummunication } from "./Titles";

// Assets.
import communicationImg from "./assets/img/communication.png";
import checkSvg from "./assets/icons/check.svg";

const Communication = () => {
  return (
    <section className="payroll_maquila_communication">
      <GradientBg className="gradient" gradientFrom="rgba(245, 245, 245, 1) 5%">
        <Container>
          <TitleCummunication />
          <Row className="mt-5 justify-content-between">
            <Col lg={6} className="">
              <h3 className="mb-4">Recibo de Nómina</h3>
              <p className="mb-4">
                Brindamos una solución integral de recibos de pago que comienza
                por definir su contenido, durante la fase de implementación.
                Luego, cada periodo, generamos certificados digitales (cfdi’s),
                los validamos contra el Servicio de Administración Tributaria
                (SAT) y entregamos los recibos (vía correo electrónico) a todos
                los empleados.
              </p>
              <ul className="list-unstyled">
                <li className="mb-2 d-flex">
                  <span className="me-2">
                    <img src={checkSvg} alt="check icon" />{" "}
                  </span>{" "}
                  <p>
                    Logotipo, diseño e información a mostrar 100%
                    personalizables
                  </p>
                </li>
                <li className="mb-2 d-flex">
                  <span className="me-2">
                    <img src={checkSvg} alt="check icon" />{" "}
                  </span>{" "}
                  <p>
                    Sección de anuncios para mostrar avisos generales o
                    segmentados
                  </p>
                </li>
                <li className="d-flex">
                  <span className="me-2 ">
                    <img src={checkSvg} alt="check icon" />{" "}
                  </span>{" "}
                  <p>
                    URL encriptada y seguridad máxima en servidores para
                    almacenamiento de los PDF’s
                  </p>
                </li>
              </ul>
            </Col>
            <Col
              lg={6}
              className="d-flex justify-content-lg-end justify-content-center"
            >
              <img
                src={communicationImg}
                alt="Recibo de Nómina Nomi"
                width={555}
                height={455}
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </GradientBg>
    </section>
  );
};
export default Communication;
