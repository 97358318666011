// Librerias.
import React from "react";
import { Container } from "react-bootstrap";
import ReturnToServices from "../components-base/ReturnToServices";
import styled from "styled-components";

// Components Software.
import { TitleUI } from "./Titles";

// Assets.
import { PrototypeSvg, ScreensSvg, WireframesSvg } from "./assets/index.js";
import prototypeBgImg from "./assets/ui/prototype-bg.svg";
import screensBgImg from "./assets/ui/screens-bg.svg";
import wireframesBgImg from "./assets/ui/wireframes-bg.svg";

const BgCard = styled.div`
  background: #521798 no-repeat left center
    url(${(props) => (props.bgCard ? props.bgCard : props.bgCard)});
`;

const CardUI = ({ icon, title, children, bgCard }) => {
  return (
    <div className="card">
      <BgCard bgCard={bgCard} className="card-header">
        <div className="card-header__icon">{icon}</div>
        <div className="card-header__title">{title}</div>
      </BgCard>
      <div className="card-body">{children}</div>
    </div>
  );
};

const Ui = () => {
  return (
    <section className="software_ui">
      <Container>
        <TitleUI />
        <div className="cards margin">
          <CardUI
            icon={<WireframesSvg />}
            title="WIREFRAMES"
            bgCard={wireframesBgImg}
          >
            <div>
              Los wireframes son bosquejos de diferentes niveles de calidad:
              baja (a mano), media (blanco y negro), alta (con funcionalidad).
              Estas formas y elementos nos dan idea de cómo será la composición;
              texto, botones, imágenes, sliders…que sirven para proyectar los
              niveles de interacción y funcionalidad en cada una de las
              pantallas de la aplicación.
            </div>
          </CardUI>

          <CardUI icon={<ScreensSvg />} title="PANTALLAS" bgCard={screensBgImg}>
            <div>
              El diseño de pantallas es una representación visual estática de
              cada una de las secciones que componen y sobre las cuales se
              navega en la aplicación. A este nivel del desarrollo, y con estos
              elementos visuales, es posible realizar pruebas de usuario (A/B
              testing) para evaluar el funcionamiento y aceptación de las
              decisiones de experiencia de usuario que se implementaron.
            </div>
          </CardUI>

          <CardUI
            icon={<PrototypeSvg />}
            title="PROTOTIPO"
            bgCard={prototypeBgImg}
          >
            <div>
              Es la interconexión de todas las imágenes, a modo de flujo. Se
              hace con el diseño de todas las pantallas. Una vez que se tiene el
              prototipo, visualmente no cambia la aplicación para empezar a
              convertirse en software y comenzar el desarrollo: el código, la
              programación. Esta etapa brinda una idea definitiva de cómo se ve,
              funciona y ‘se siente’ la aplicación.
            </div>
          </CardUI>
        </div>
        <ReturnToServices />
      </Container>
    </section>
  );
};
export default Ui;
