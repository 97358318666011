// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Software.
import { TitleProduction } from "./Titles";
import ReturnToServices from "../components-base/ReturnToServices";

// Assets.
import { DeploymentSvg, MaintenanceSvg, SupportSvg } from "./assets/index.js";

const CardProduction = ({ icon, title, text }) => {
  return (
    <div className="card">
      <div className="card__title">
        {icon}
        {title}
      </div>
      <div className="card__text">{text}</div>
    </div>
  );
};

const Production = () => {
  return (
    <section className="software_production">
      <Container>
        <TitleProduction />
        <div className="cards margin">
          <CardProduction
            icon={<DeploymentSvg />}
            title="Deployment"
            text="Nuestro equipo de deployment se encarga de publicar la aplicación primero en un ambiente de desarrollo, para realizar las pruebas internas, y posteriormente entrar en la etapa de UAT (user acceptance testing). Si todo va bien en estas pruebas se puede migrar a un ambiente de producción."
          ></CardProduction>
          <CardProduction
            icon={<MaintenanceSvg />}
            title="Mantenimiento"
            text="Una vez en producción, las aplicaciones pueden ser sujetas a cambios necesarios que se ajustan a las necesidades cambiantes y retroalimentación del usuario. Por ello, diseñamos un plan de mantenimiento a la medida para cada uno de nuestros productos."
          ></CardProduction>
          <CardProduction
            icon={<SupportSvg />}
            title="Soporte"
            text="Destinamos a un equipo de soporte, capaz de brindar soluciones rápidas y eficientes, independientemente de la causa del problema: conexión, usabilidad, programación… Enfocándonosla siempre en la solución y en la atención al cliente."
          ></CardProduction>
        </div>
        <ReturnToServices />
      </Container>
      <div className="line"></div>
    </section>
  );
};
export default Production;
