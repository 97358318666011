// Librerias.
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { a } from "react-router-dom";

// Assets.
import handImg from "./assets/img/hand.png";

// Hooks.
import useWindow from "../hooks/useWindow";
const MyCompany = () => {
  const windowSize = useWindow();

  return (
    <section className="payroll_maquila_my_company position-relative">
      <Container>
        <img
          src={handImg}
          alt="my-company"
          width={840}
          height={770}
          className="hand img-fluid"
        />
        {windowSize.width < 600 && (
          <h2 className="kiosko-title">Kiosko de Atención a Empleados</h2>
        )}
        <Row>
          <Col lg={{ span: 6, offset: 6 }} className="col-my-company">
            {windowSize.width > 600 && (
              <h2 className="mb-4">
                Kiosko de atención personalizada a empleados
              </h2>
            )}
            <p className="description">
              Ponemos toda la información de tus empleados, literalmente, en la
              palma de sus manos…
            </p>
            <p className="description">
              Podrán acceder, de forma segura, en línea desde cualquier
              dispositivo (móvil o de escritorio) para revisar y/o gestionar
              todos los datos y asuntos relacionados con su nómina.
            </p>
            <div className="my-company mt-4">
              <p>
                My<span className="fw-bold">[Company]</span>
              </p>
              <p>
                <span className="text-white">
                  Para más información sobre nuestra app de atención
                  personalizada a empleados:
                </span>{" "}
                <br />
                <a href="/my-company" className="fw-bold">
                  PULSE AQUÍ
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default MyCompany;
