// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Header } from "../components-base";

// Assets.
import heroBgD from "./assets/img/hero-bg-d.jpg";
import heroBgP from "./assets/img/hero-bg-p.jpg";
const Hero = () => {
  return (
    <Header
      uri_bg_d={heroBgD}
      uri_bg_t={heroBgP}
      uri_bg_p={heroBgP}
      className="dicoma_hero"
      height={1547}
    >
      <Container className="hero-text">
        <h1 className="text-center">La solución más eficiente para el</h1>
        <div className="d-flex justify-content-center">
          <h1 className="sub-title">Registro de Consumos del Comedor</h1>
        </div>
      </Container>
      <div className="dicoma_hero__bg"></div>
    </Header>
  );
};
export default Hero;
