// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

const Privacy = () => {
  return (
    <section className="contact_privacy">
      <Container>
        <h2>Aviso de Privacidad</h2>
        <p className="date">Última actualización 21 de agosto de 2021.</p>
        <div className="description">
          <p>
            Estamos conscientes de la importancia que tiene para usted saber
            cómo utilizamos sus datos personales y le agradecemos que confíe que
            lo haremos con cuidado y sensatez. Este Aviso de Privacidad describe
            la forma en que <span>Nomiservicios del Bajío S.A. de C.V.</span>{" "}
            ("Nomi") recopilan y tratan sus datos personales a través de los
            sitios web, dispositivos, productos, servicios y aplicaciones de
            Nomi que hacen referencia a este Aviso de Privacidad (conjuntamente
            "Servicios de Nomi"). 
            <span>
              {" "}
              Al utilizar los Servicios de Nomi, usted está dando su
              consentimiento a las prácticas descritas en este Aviso de
              Privacidad.
            </span>
          </p>
          <p>
            Si tiene preguntas sobre como recabamos y usamos sus datos
            personales o sobre este aviso de privacidad, por favor póngase en
            contacto con nosotros. Nuestros datos de contacto son:{" "}
          </p>
          <p>- Teléfono: +52 (55) 7688 - 4282 </p>
          <p>- Correo electrónico: contacto@nomi.mx </p>
          <p>
            Lea este aviso de privacidad con atención, ya que le ayudará a
            comprender lo que hacemos con la información que recopilamos.{" "}
          </p>
        </div>
        <div className="question">
          <p className="question--title">1. ¿QUÉ INFORMACIÓN RECOPILAMOS? </p>
          <p>
            <span>En resumen: </span> Recopilamos la información personal que
            nos proporciona. 
          </p>
          <p>
            Recopilamos información personal que usted nos proporciona
            voluntariamente cuando se registra en el sitio web, expresa su
            interés en obtener información sobre nosotros o nuestros productos y
            servicios, cuando participa en actividades en el sitio web o cuando
            se comunica con nosotros. 
          </p>
          <p>
             La información personal que recopilamos depende del contexto de sus
            interacciones con nosotros y el sitio web, las elecciones que haga y
            los productos y funciones que utilice. La información personal que
            recopilamos puede incluir lo siguiente: {" "}
          </p>
          <p>
             Información personal proporcionada por
            usted. Recopilamos nombres; correos electrónicos; nombres de
            usuario; contraseñas; preferencias de contacto; datos de contacto o
            autenticación; y otra información similar.  
          </p>
          <p>
             Toda la información personal que nos proporcione debe ser
            verdadera, completa y precisa, y debe notificarnos cualquier cambio
            en dicha información personal.{" "}
          </p>
          <p>
            <span>Información recopilada automáticamente</span>
          </p>
          <p>
            <span>En resumen: </span>Cierta información, como su dirección de
            Protocolo de Internet (IP) y / o características del navegador y del
            dispositivo, se recopila automáticamente cuando visita nuestro sitio
            web.   {" "}
          </p>
          <p>
             Recopilamos automáticamente cierta información cuando visita, usa o
            navega por el sitio web. Esta información no revela su identidad
            específica (como su nombre o información de contacto), pero puede
            incluir información de uso y dispositivo, como su dirección IP,
            navegador y características del dispositivo, sistema operativo,
            preferencias de idioma, URL de referencia, nombre del dispositivo,
            país, ubicación, información sobre cómo y cuándo utiliza
            nuestro sitio web y otra información técnica. Esta información es
            principalmente necesaria para mantener la seguridad y el
            funcionamiento de nuestro sitio web, y para nuestros fines de
            análisis e informes internos.  
          </p>
          <p>
             Como muchas empresas, también recopilamos información a través de
            cookies y tecnologías similares.{" "}
          </p>
          <p>La información que recopilamos incluye: </p>
          <ul>
            <li>
              Datos de registro y uso. Los datos de uso de registro
              y es relacionada con el servicio, el diagnóstico, el uso y el
              rendimiento información de nuestros servidores de forma automática
              a cobro revertido cuando accede o usa nuestra página web y el cual
              dejamos constancia en los archivos de registro. Dependiendo de
              cómo interactúe con nosotros, estos datos de registro pueden
              incluir su dirección IP, información del dispositivo, tipo de
              navegador y configuración e información sobre su actividad en
              el sitio web (como las marcas de fecha / hora asociadas con su
              uso, páginas y archivos vistos, búsquedas y otras acciones que
              realiza, como las funciones que usa), información de eventos del
              dispositivo (como la actividad del sistema, informes de errores (a
              veces llamados "volcados por caída") y configuraciones de
              hardware).
            </li>
            <li>
              Datos del dispositivo. Recopilamos datos del dispositivo, como
              información sobre su computadora, teléfono, tableta u otro
              dispositivo que utilice para acceder al sitio web. Dependiendo del
              dispositivo utilizado, estos datos del dispositivo pueden incluir
              información como su dirección IP (o servidor proxy), números de
              identificación de dispositivo y aplicación, ubicación, tipo de
              navegador, modelo de hardware Proveedor de servicios de Internet y
              / o operador de telefonía móvil, sistema operativo y configuración
              del sistema. información. 
            </li>
            <li>
              Datos de localización. Recopilamos datos de ubicación, como
              información sobre la ubicación de su dispositivo, que puede ser
              precisa o imprecisa. La cantidad de información que recopilamos
              depende del tipo y la configuración del dispositivo que utiliza
              para acceder al sitio web. Por ejemplo, podemos utilizar GPS y
              otras tecnologías para recopilar datos de geolocalización que nos
              indiquen su ubicación actual (según su dirección IP). Puede optar
              por no permitirnos recopilar esta información, ya sea rechazando
              el acceso a la información o desactivando la configuración de
              Ubicación en su dispositivo. Sin embargo, tenga en cuenta que si
              opta por no participar, es posible que no pueda utilizar ciertos
              aspectos de los Servicios. 
            </li>
          </ul>
        </div>
        <div className="question">
          <p className="question--title">2. ¿CÓMO UTILIZAMOS SU INFORMACIÓN?</p>
          <p>
            <span>En resumen: </span> Procesamos su información con fines
            basados ​​en intereses comerciales legítimos, el cumplimiento de
            nuestro contrato con usted, el cumplimiento de nuestras obligaciones
            legales y / o su consentimiento.
          </p>
          <p>
             Usamos la información personal recopilada a través de nuestro sitio
            web para una variedad de fines comerciales que se describen a
            continuación. Procesamos su información personal para estos fines
            basándonos en nuestros intereses comerciales legítimos, con el fin
            de celebrar o ejecutar un contrato con usted, con su consentimiento
            y / o para cumplir con nuestras obligaciones legales. Indicamos los
            motivos de procesamiento específicos en los que confiamos junto a
            cada propósito que se enumera a continuación.
          </p>
          <p>Usamos la información que recopilamos o recibimos:</p>
          <ul>
            <li>
              Para facilitar la creación de cuentas y el proceso de inicio de
              sesión. Si elige vincular su cuenta con nosotros a una cuenta de
              un tercero (como su cuenta de Google o Facebook), usamos la
              información que nos permitió recopilar de esos terceros para
              facilitar la creación de la cuenta y el proceso de inicio de
              sesión para el desempeño del contrato.
            </li>
            <li>
              Publicar testimonios. Publicamos testimonios en nuestro sitio
              web que pueden contener información personal. Antes de publicar un
              testimonio, obtendremos su consentimiento para usar su nombre y el
              contenido del testimonio. Si desea actualizar o eliminar su
              testimonio, contáctenos a contacto@nomi.mx y asegúrese de incluir
              su nombre, ubicación del testimonio e información de contacto.
            </li>
            <li>
              Solicite comentarios. Podemos utilizar su información para
              solicitar comentarios y comunicarnos con usted sobre su uso de
              nuestro sitio web.
            </li>
            <li>
              Para habilitar las comunicaciones de usuario a usuario. Podemos
              utilizar su información para permitir las comunicaciones de
              usuario a usuario con el consentimiento de cada usuario
            </li>
            <li>
              Para administrar cuentas de usuario. Podemos utilizar su
              información con el fin de administrar nuestra cuenta y mantenerla
              en funcionamiento.
            </li>
            <li>
              Para enviarle información administrativa. Podemos utilizar su
              información personal para enviarle información sobre
              productos, servicios y nuevas funciones y / o información sobre
              cambios en nuestros términos, condiciones y políticas.
            </li>
            <li>
              Para proteger nuestros Servicios. Podemos utilizar su información
              como parte de nuestros esfuerzos para mantener nuestro sitio
              web seguro y protegido (por ejemplo, para la supervisión y
              prevención de fraudes).
            </li>
            <li>
              Para hacer cumplir nuestros términos, condiciones y políticas con
              fines comerciales, para cumplir con los requisitos legales y
              reglamentarios o en relación con nuestro contrato.
            </li>
            <li>
              Para responder a solicitudes legales y prevenir daños. Si
              recibimos una citación u otra solicitud legal, es posible que
              debamos inspeccionar los datos que tenemos para determinar cómo
              responder.
            </li>
            <li>
              Cumplir y gestionar sus pedidos. Podemos utilizar su información
              para cumplir y administrar sus pedidos, pagos, devoluciones e
              intercambios realizados a través del sitio web.
            </li>
            <li>
              Administrar sorteos y concursos. Podemos usar su información para
              administrar sorteos de premios y concursos cuando elija participar
              en nuestros concursos.
            </li>
            <li>
              Brindar y facilitar la entrega de servicios al usuario. Podemos
              utilizar su información para brindarle el servicio solicitado.
            </li>
            <li>
              Responder a las consultas de los usuarios / ofrecer soporte a los
              usuarios. Podemos utilizar su información para responder a sus
              consultas y resolver cualquier problema potencial que pueda tener
              con el uso de nuestros Servicios.
            </li>
            <li>
              Para enviarle comunicaciones de marketing y
              promocionales. Nosotros y / o nuestros socios de marketing
              externos podemos utilizar la información personal que nos envíe
              para nuestros fines de marketing , si esto está de acuerdo con sus
              preferencias de marketing. Por ejemplo, al expresar un interés en
              obtener información sobre nosotros o nuestro sitio web,
              suscribirse a marketing o contactarnos de otra manera,
              recopilaremos su información personal. Puede optar por no recibir
              nuestros correos electrónicos de marketing en cualquier momento.
            </li>
            <li>
              Entregarle publicidad dirigida. Podemos utilizar su información
              para desarrollar y mostrar contenido y publicidad personalizados
              (y trabajar con terceros que lo hagan) adaptados a sus intereses y
              / o ubicación y para medir su efectividad.
            </li>
            <li>
              Para otros fines comerciales. Podemos utilizar su información para
              otros fines comerciales, como análisis de datos, identificación de
              tendencias de uso, determinación de la efectividad de nuestras
              campañas promocionales y para evaluar y mejorar nuestro sitio web,
              productos, marketing y su experiencia. Podemos utilizar y
              almacenar esta información en forma agregada y anónima para que no
              se asocie con usuarios finales individuales y no incluya
              información personal. No usaremos información personal
              identificable sin su consentimiento.
            </li>
          </ul>
        </div>
        <div className="question">
          <p className="question--title">
            3. ¿SE COMPARTIRÁ SU INFORMACIÓN CON ALGUIEN?
          </p>
          <p>
            <span>En resumen: </span> Solo compartimos información con su
            consentimiento, para cumplir con las leyes, para brindarle
            servicios, para proteger sus derechos o para cumplir con
            obligaciones comerciales.
          </p>
          <p>
            Podemos procesar o compartir sus datos que tenemos con base en el
            siguiente fundamento legal:
          </p>
          <ul>
            <li>
              Consentimiento: Podemos procesar sus datos si nos ha dado su
              consentimiento específico para usar su información personal para
              un propósito específico.
            </li>
            <li>
              Intereses legítimos: Podemos procesar sus datos cuando sea
              razonablemente necesario para lograr nuestros intereses
              comerciales legítimos.
            </li>
            <li>
              Ejecución de un contrato: Cuando hayamos celebrado un contrato con
              usted, podemos procesar su información personal para cumplir con
              los términos de nuestro contrato.
            </li>
            <li>
              Obligaciones legales: Podemos divulgar su información cuando
              estemos legalmente obligados a hacerlo para cumplir con la ley
              aplicable, las solicitudes gubernamentales, un procedimiento
              judicial, una orden judicial o un proceso legal, como en respuesta
              a una orden judicial o una citación (incluso en respuesta a las
              autoridades para cumplir con los requisitos de seguridad nacional
              o de aplicación de la ley).
            </li>
            <li>
              Intereses vitales: Podemos divulgar su información cuando creamos
              que es necesario para investigar, prevenir o tomar medidas con
              respecto a posibles violaciones de nuestras políticas, sospecha de
              fraude, situaciones que involucren amenazas potenciales a la
              seguridad de cualquier persona y actividades ilegales, o como
              evidencia en litigio en el que estamos involucrados.
            </li>
          </ul>
          <p>
            Más específicamente, es posible que necesitemos procesar sus datos o
            compartir su información personal en las siguientes situaciones:{" "}
          </p>
          <ul>
            <li>
              Transferencias comerciales. Podemos compartir o transferir su
              información en relación con, o durante las negociaciones de,
              cualquier fusión, venta de activos de la empresa, financiamiento o
              adquisición de todo o una parte de nuestro negocio a otra empresa.
            </li>
            <li>
              Proveedores, consultores y otros proveedores de servicios
              externos. Podemos compartir sus datos con proveedores externos,
              proveedores de servicios, contratistas o agentes que brindan
              servicios para nosotros o en nuestro nombre y requieren acceso a
              dicha información para realizar ese trabajo. Los
              ejemplos incluyen: Procesamiento de pagos, análisis de datos,
              entrega de correo electrónico, servicios de alojamiento, servicio
              al cliente y esfuerzos de marketing. Podemos permitir que terceros
              seleccionados utilicen tecnología de seguimiento en el sitio web,
              lo que les permitirá recopilar datos en nuestro nombre sobre cómo
              interactúa con nuestro sitio web a lo largo del tiempo. Esta
              información puede usarse para, entre otras cosas, analizar y
              rastrear datos, determinar la popularidad de cierto
              contenido, páginas o características, y comprender mejor la
              actividad en línea. A menos que se describa en este aviso, no
              compartimos, vendemos, alquilamos ni intercambiamos su información
              con terceros con fines promocionales.
            </li>
          </ul>
        </div>
        <div className="question">
          <p className="question--title">
            4. ¿CON QUIÉN SE COMPARTIRÁ SU INFORMACIÓN? 
          </p>
          <p>
            <span>En resumen: </span> Solo compartimos información con los
            siguientes terceros.
          </p>
          <p>
            Solo compartimos y divulgamos su información con los siguientes
            terceros. Hemos clasificado a cada una de las partes para que pueda
            comprender fácilmente el propósito de nuestras prácticas de
            recopilación y procesamiento de datos. Si hemos procesado sus datos
            en función de su consentimiento y desea revocar su consentimiento,
            comuníquese con nosotros.
          </p>
          <ul>
            <li>
              Optimización de contenido <span>(Fuentes de Google)</span>
            </li>

            <li>
              Pruebas de sitios web
              <span>(Optimizador de sitios web de Google)</span>
            </li>
          </ul>
        </div>
        <div className="question">
          <p className="question--title">
            5. ¿UTILIZAMOS COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO?
          </p>
          <p>
             <span>En resumen: </span> Podemos utilizar cookies y otras
            tecnologías de seguimiento para recopilar y almacenar su
            información.
          </p>
          <p>
             Podemos utilizar cookies y tecnologías de seguimiento similares
            (como balizas web y píxeles) para acceder o almacenar
            información. La información específica sobre cómo usamos dichas
            tecnologías y cómo puede rechazar ciertas cookies se establece en
            nuestro Aviso de cookies.
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            6. ¿POR CUÁNTO TIEMPO CONSERVAMOS SU INFORMACIÓN?
          </p>
          <p>
            <span>En resumen: </span> Conservamos su información durante el
            tiempo que sea necesario para cumplir con los propósitos descritos
            en este aviso de privacidad, a menos que la ley exija lo contrario.
          </p>
          <p>
             Solo conservaremos su información personal durante el tiempo que
            sea necesario para los fines establecidos en este aviso de
            privacidad, a menos que la ley exija o permita un período de
            retención más prolongado (como impuestos, contabilidad u otros
            requisitos legales). Ningún propósito en este aviso requerirá que
            mantengamos su información personal por más tiempo que el período de
            tiempo en el que los usuarios tienen una cuenta con nosotros.
          </p>
          <p>
            Cuando no tengamos una necesidad comercial legítima continua para
            procesar su información personal, eliminaremos o anonimizaremos
            dicha información o, si esto no es posible (por ejemplo, porque su
            información personal se ha almacenado en archivos de respaldo), lo
            haremos de manera segura almacenar su información personal y
            aislarla de cualquier procesamiento posterior hasta que sea posible
            la eliminación.
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            7. ¿CÓMO MANTENEMOS SEGURA SU INFORMACIÓN?
          </p>
          <p>
            <span>En resumen: </span> Nuestro objetivo es proteger su
            información personal a través de un sistema de medidas de seguridad
            organizativas y técnicas.
          </p>
          <p>
            Hemos implementado medidas de seguridad técnicas y organizativas
            apropiadas diseñadas para proteger la seguridad de cualquier
            información personal que procesamos. Sin embargo, a pesar de
            nuestras salvaguardas y esfuerzos para proteger su información, no
            se puede garantizar que ninguna transmisión electrónica a través de
            Internet o tecnología de almacenamiento de información sea 100%
            segura, por lo que no podemos prometer ni garantizar que los piratas
            informáticos, los ciberdelincuentes u otros terceros no autorizados
            no serán capaz de vencer nuestra seguridad y recopilar, acceder,
            robar o modificar su información de manera inapropiada. Aunque
            haremos todo lo posible para proteger su información personal, la
            transmisión de información personal hacia y desde nuestro sitio
            web es bajo su propio riesgo. Solo debe acceder al sitio web en un
            entorno seguro.
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            8. ¿RECOPILAMOS INFORMACIÓN DE MENORES?
          </p>
          <p>
            <span>En resumen: </span> No recopilamos ni comercializamos a
            sabiendas datos de niños menores de 18 años.
          </p>
          <p>
            No solicitamos a sabiendas datos ni comercializamos a niños menores
            de 18 años. Al utilizar el sitio web, usted declara que tiene al
            menos 18 años o que es el padre o tutor de dicho menor y da su
            consentimiento para el uso del sitio web por parte de dicho menor
            dependiente. Si nos enteramos de que se ha recopilado información
            personal de usuarios menores de 18 años, desactivaremos la cuenta y
            tomaremos medidas razonables para eliminar de inmediato dichos datos
            de nuestros registros. Si tiene conocimiento de algún dato que
            hayamos recopilado de niños menores de 18 años, comuníquese con
            nosotros a contacto@nomi.mx.
          </p>
          <p>
            <span>Información de la cuenta </span>
          </p>
          <p>
            Si en algún momento desea revisar o cambiar la información de su
            cuenta o cancelar su cuenta, puede:
          </p>
          <p>
            Inicie sesión en la configuración de su cuenta y actualice su cuenta
            de usuario. Si solicita cancelar su cuenta, desactivaremos o
            eliminaremos su cuenta e información de nuestras bases de datos
            activas. Sin embargo, podemos retener cierta información en nuestros
            archivos para evitar fraudes, solucionar problemas, ayudar con
            cualquier investigación, hacer cumplir nuestros Términos de uso y /
            o cumplir con los requisitos legales aplicables.
          </p>
          <p>
            <span>Cookies y tecnologías similares:</span> la mayoría de los
            navegadores web están configurados para aceptar cookies de forma
            predeterminada. Si lo prefiere, normalmente puede elegir configurar
            su navegador para eliminar las cookies y rechazarlas. Si elige
            eliminar las cookies o rechazarlas, esto podría afectar ciertas
            características o servicios de nuestro sitio web.
          </p>
          <p>
            <span>Opción de no recibir marketing por correo electrónico:</span>
             puede darse de baja de nuestra lista de correo electrónico de
            marketing en cualquier momento haciendo clic en el enlace para darse
            de baja en los correos electrónicos que le enviamos o poniéndose en
            contacto con nosotros utilizando los detalles que se proporcionan a
            continuación. Luego será eliminado de la lista de correo electrónico
            de marketing; sin embargo, aún podemos comunicarnos con usted, por
            ejemplo, para enviarle correos electrónicos relacionados con el
            servicio que son necesarios para la administración y el uso de su
            cuenta, para responder a las solicitudes de servicio o para otros
            fines no comerciales. Para optar por no participar, puede:
          </p>
          <ul>
            <li>
              Acceda a la configuración de su cuenta y actualice sus
              preferencias.
            </li>
          </ul>
        </div>
        <div className="question">
          <p className="question--title">
            9. CONTROLES PARA FUNCIONES DE NO SEGUIMIENTO
          </p>
          <p>
            La mayoría de los navegadores web y algunos sistemas operativos y
            aplicaciones móviles incluyen una función o configuración No
            rastrear (“DNT”) que puede activar para indicar su preferencia de
            privacidad para que no se controlen y recopilen datos sobre sus
            actividades de navegación en línea. En esta etapa, no se ha
            finalizado ningún estándar tecnológico uniforme para reconocer e
            implementar señales DNT. Como tal, actualmente no respondemos a las
            señales del navegador DNT ni a ningún otro mecanismo que comunique
            automáticamente su elección de no ser rastreado en línea. Si se
            adopta un estándar para el seguimiento en línea que debemos seguir
            en el futuro, le informaremos sobre esa práctica en una versión
            revisada de este aviso de privacidad.
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            10. ¿TIENEN LOS RESIDENTES DE CALIFORNIA DERECHOS DE PRIVACIDAD
            ESPECÍFICOS?
          </p>
          <p>
            <span>En resumen: </span> Sí, si es residente de California, se le
            otorgan derechos específicos con respecto al acceso a su información
            personal.
          </p>
          <p>
             La Sección 1798.83 del Código Civil de California, también conocida
            como la ley “Shine The Light”, permite a nuestros usuarios que son
            residentes de California solicitar y obtener de nosotros, una vez al
            año y sin cargo, información sobre categorías de información
            personal (si corresponde) que nosotros divulgada a terceros con
            fines de marketing directo y los nombres y direcciones de todos los
            terceros con los que compartimos información personal en el año
            calendario inmediatamente anterior. Si usted es un residente de
            California y desea realizar dicha solicitud, envíenos su solicitud
            por escrito utilizando la información de contacto que se proporciona
            a continuación.
          </p>
          <p>
            Si es menor de 18 años, reside en California y tiene una cuenta
            registrada en el sitio web, tiene derecho a solicitar la eliminación
            de los datos no deseados que publique públicamente en el sitio
            web. Para solicitar la eliminación de dichos datos, comuníquese con
            nosotros utilizando la información de contacto que se proporciona a
            continuación e incluya la dirección de correo electrónico asociada
            con su cuenta y una declaración de que reside en California. Nos
            aseguraremos de que los datos no se muestren públicamente en
            el sitio web, pero tenga en cuenta que es posible que los datos no
            se eliminen completa o completamente de todos nuestros sistemas (por
            ejemplo, copias de seguridad, etc.).
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            11. ¿HACEMOS ACTUALIZACIONES A ESTE AVISO? 
          </p>
          <p>
            <span>En resumen: </span> Sí, actualizaremos este aviso según sea
            necesario para cumplir con las leyes pertinentes.
          </p>
          <p>
             Es posible que actualicemos este aviso de privacidad de vez en
            cuando. La versión actualizada se indicará con una fecha “Revisada”
            actualizada y la versión actualizada entrará en vigor tan pronto
            como esté disponible. Si realizamos cambios sustanciales a este
            aviso de privacidad, podemos notificarle publicando un aviso de
            manera prominente de dichos cambios o enviándole directamente una
            notificación. Le recomendamos que revise este aviso de privacidad
            con frecuencia para estar informado de cómo protegemos su
            información.
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            12. ¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?
          </p>
          <p>
            Si tiene preguntas o comentarios sobre este aviso, puede enviarnos
            un correo electrónico a contacto@nomi.mx
          </p>
        </div>
        <div className="question">
          <p className="question--title">
            13. ¿CÓMO PUEDE REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE
            RECOPILAMOS DE USTED?
          </p>
          <p>
            De acuerdo con las leyes aplicables de su país, es posible que tenga
            derecho a solicitar acceso a la información personal que recopilamos
            de usted, cambiar esa información o eliminarla en algunas
            circunstancias. Para solicitar revisar, actualizar o eliminar su
            información personal, envíe un formulario de solicitud
            a contacto@nomi.mx.
          </p>
        </div>
      </Container>
    </section>
  );
};
export default Privacy;
