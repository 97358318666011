// Librerias.
import React from "react";

// Components Base.
import { Container, Row, Col } from "react-bootstrap";

// Assets.
import { ImgOurHistory } from "./Assets";

const OurHistory = () => {
  return (
    <section className="the-company_our-history">
      <Container>
        <h2>Nuesta Historia</h2>
        <Row className="justify-content-lg-between justify-content-center flex-xl-nowrap flex-wrap gap-2 ">
          <Col lg={7} className="the-company_our-history__text">
            <p>
              La historia de NOMI comienza con Eduardo Trejo Sánchez,
              programador destacado por desarrollar exitosos sistemas de
              contabilidad para empresas transnacionales, quien detecta la
              necesidad de acortar el tiempo en el que éstas calculaban sus
              nóminas, además de garantizar la precisión y seguridad en el
              manejo de su información.
            </p>
            <p>
              En 1988 decide incursionar en el mundo de los negocios y, junto
              con un talentoso equipo de desarrolladores, lanzan al mercado el
              GSN (Generador de Sistemas de Nómina).
            </p>
            <p>
              Desde el inicio, el sistema tuvo gran aceptación debido a la
              velocidad, precisión y seguridad de procesamiento de nóminas y, al
              cabo de unos años, logró consolidarse como uno de los más
              confiables del mercado.
            </p>
            <p>
              Naturalmente, llamó la atención de múltiples empresas
              internacionales que adquirieron licencias y/o, por solicitud
              propia, la tercerización del proceso, propiciando así el
              nacimiento de nuestro servicio de Maquila de Nómina.
            </p>
            <p>
              Hoy en día, a esta gran experiencia se suma la refrescante visión
              de una nueva generación, cuyo enfoque es: 1) Aprovechar la
              transformación digital para reinventar su infraestructura
              tecnológica y 2) llevar al máximo nivel la experiencia de servicio
              y atención al cliente.
            </p>
          </Col>

          <Col lg={5} className="the-company_our-history__img">
            <ImgOurHistory className="the-company_our-history__img--size" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurHistory;
