// Librerias.
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Components Base.
import { Header } from "../components-base";

// Assets.
import heroBgD from "./assets/img/hero-bg-d.jpg";
import heroBgP from "./assets/img/hero-bg-p.jpg";
import heroPhones from "./assets/img/hero-phone.png";

const Hero = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const animation = useAnimation();

  useEffect(() => {
    console.log("use effect hook, inView = ", inView);
    if (inView) {
      animation.start({
        y: 280,
        scale: 0.62,
        transition: { duration: 0.7 },
      });
    }
    if (!inView) {
      animation.start({
        y: 0,
        scale: 1,
        transition: { duration: 0.7 },
      });
    }
  }, [inView]);
  return (
    <Header
      uri_bg_d={heroBgD}
      uri_bg_p={heroBgP}
      className="cfdis_hero position-relative"
      height={1547}
    >
      <div className="hero-text">
        <h1 className="text-center">Imprimir es cosa del pasado…</h1>
        <div className="d-flex justify-content-center">
          <h1 className="sub-title">Gestión integral de CFDI’s</h1>
        </div>
      </div>
      <div className="d-flex justify-content-center hide-phone">
        <motion.img
          src={heroPhones}
          alt="cfdis phones"
          width={1170}
          height={658}
          className="img-fluid phones-img hide-phone"
          animate={animation}
        ></motion.img>
      </div>
      <div ref={ref} className="referenceAnimationBox"></div>
    </Header>
  );
};
export default Hero;
