// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import {
  CompassSvg,
  ExperienceSvg,
  InterfaceSvg,
  SoftwareSvg,
  StrategySvg,
  GearSvg,
} from "./assets/index.js";

const CardService = ({ icon, title, description, to = "./" }) => {
  return (
    <div className="card">
      <div className="card__icon">{icon}</div>
      <div className="card__title">{title}</div>
      <div className="card__description">{description}</div>
      <a href={to}>Leer Más</a>
    </div>
  );
};

const SliderCards = () => {
  return (
    <div class="slider">
      <div class="slide-track cards">
        <div class="slide">
          <CardService
            icon={<StrategySvg />}
            title="ESTRATEGIA"
            description="Detectamos el problema y cómo vamos a solucionarlo, con qué recursos..."
            to="#strategy"
          ></CardService>
        </div>
        <div class="slide">
          <CardService
            icon={<CompassSvg />}
            title="ARQUITECTURA"
            description="En esta etapa se define cómo estará compuesta la infraestructura que…"
            to="#arquitecture"
          ></CardService>
        </div>
        <div className="slide">
          <CardService
            icon={<ExperienceSvg />}
            title="EXPERIENCIA DEL USUARIO"
            description="La experiencia del usuario es el nivel de satisfacción de una persona al usar un…"
            to="#ux"
          ></CardService>
        </div>
        <div className="slide">
          <CardService
            icon={<InterfaceSvg />}
            title="INTERFAZ GRÁFICA (UI)"
            description="La interfaz gráfica es el medio de interacción con el cliente y/o el usuario final. Por ello…"
            to="#ui"
          ></CardService>
        </div>
        <div className="slide">
          <CardService
            icon={<SoftwareSvg />}
            title="SOFTWARE"
            description="Al terminar la etapa de diseño, se entrega el producto al equipo de programación…"
            to="#software"
          ></CardService>
        </div>
        <div className="slide">
          <CardService
            icon={<GearSvg />}
            title="PRODUCCIÓN"
            description="En esta etapa se lanza la aplicación una vez terminada. La aplicación es un producto..."
            to="#production"
          ></CardService>
        </div>
        <div className="slide mobile-hidden">
          <CardService
            icon={<StrategySvg />}
            title="ESTRATEGIA"
            description="Detectamos el problema y cómo vamos a solucionarlo, con qué recursos..."
            to="#strategy"
          ></CardService>
        </div>
        <div class="slide mobile-hidden">
          <CardService
            icon={<CompassSvg />}
            title="ARQUITECTURA"
            description="En esta etapa se define cómo estará compuesta la infraestructura que…"
            to="#arquitecture"
          ></CardService>
        </div>
        <div className="slide mobile-hidden">
          <CardService
            icon={<ExperienceSvg />}
            title="EXPERIENCIA DEL USUARIO"
            description="La experiencia del usuario es el nivel de satisfacción de una persona al usar un…"
            to="#ux"
          ></CardService>
        </div>
        <div className="slide mobile-hidden">
          <CardService
            icon={<InterfaceSvg />}
            title="INTERFAZ GRÁFICA (UI)"
            description="La interfaz gráfica es el medio de interacción con el cliente y/o el usuario final. Por ello…"
            to="#ui"
          ></CardService>
        </div>
        <div className="slide mobile-hidden">
          <CardService
            icon={<SoftwareSvg />}
            title="SOFTWARE"
            description="Al terminar la etapa de diseño, se entrega el producto al equipo de programación…"
            to="#software"
          ></CardService>
        </div>
        <div className="slide mobile-hidden">
          <CardService
            icon={<GearSvg />}
            title="PRODUCCIÓN"
            description="En esta etapa se lanza la aplicación una vez terminada. La aplicación es un producto..."
            to="#production"
          ></CardService>
        </div>
      </div>
    </div>
  );
};

const PhasesServices = () => {
  return (
    <section className="software_phases_services">
      <Container>
        <p className="software_phases_services__text">
          Desde hace más de 30 años, nos especializamos en desarrollar sistemas
          que ayuden a automatizar procesos manuales dentro de la gestión
          administrativa de su capital humano, mediante un esquema de servicio
          <span> full-cycle</span> que abarca el desarrollo de un producto
          digital completo, desde su concepción hasta el soporte al usuario.
        </p>
        <p className="margin" id="services">
          Fases del Servicio:
        </p>
      </Container>

      <SliderCards />
    </section>
  );
};
export default PhasesServices;
