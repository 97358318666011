// Librerias.
import React, { useState } from "react";
import { Container } from "react-bootstrap";

// Assets.
import integrityLogo from "./assets/welcome/integrity-logo.svg";
import showcaseTaxVault from "./assets/welcome/showcase-tax-vault.jpg";
import showcaseDisabilities from "./assets/welcome/showcase-disabilities.jpg";
import showcaseIndicents from "./assets/welcome/showcase-incidents.jpg";
import showcaseReports from "./assets/welcome/showcase-reports.jpg";
import showcaseResult from "./assets/welcome/showcase-result.jpg";
import showcaseSettlements from "./assets/welcome/showcase-settlements.jpg";
import showcaseVacations from "./assets/welcome/showcase-vacations.jpg";

const ModulShowcase = ({ showcase }) => {
  return (
    <div className="modul-showcase">
      <img src={showcase} alt="Showcase" className="img-fluid"></img>
      <Container>
        <p className="asterisk">
          *La interfaz gráfica y alcances del módulo pueden variar con base en
          los requerimientos específicos del cliente.
        </p>
      </Container>
    </div>
  );
};

const showcases = [
  {
    img: showcaseTaxVault,
    description:
      "La bóveda fiscal está pensada para gestionar los recibos de nómina de los empleados en caso de alguna aclaración; se emiten recibos por los distintos tipos de nómina y se pueden filtrar por el período específico que se precise. Se pueden consultar los archivos, enviarlos a los empleados o al sistema de contabilidad, validar si el certificado está activo con el SAT y aquí se almacenan todos los recibos.",
  },
  {
    img: showcaseVacations,
    description:
      "Este módulo es un espejo de uno con el que cuentan los empleados en su aplicación de autoservicio. Aquí los supervisores pueden recibir, aceptar o rechazar solicitudes de permisos o vacaciones de sus supervisados; en caso de rechazo, los supervisores podrán ingresar una explicación. Todo lo que se autorice quedará registrado e impactará en el saldo de vacaciones de los empleados y las incidencias de nómina.",
  },
  {
    img: showcaseDisabilities,
    description:
      "Este módulo de la aplicación tiene como objetivo que el personal de servicios médicos ingrese y registre las incapacidades, detallando información del tipo de incapacidad en cuestión: tipo de incapacidad (ej: accidente de trabajo, enfermedad natural…), fecha, duración, foto del documento emitido por el IMSS y cualquier otro detalle relevante. Aquí queda toda esta información registrada y almacenada.",
  },
  {
    img: showcaseSettlements,
    description:
      "En esta sección de la aplicación se calculan los finiquitos y se generan los documentos correspondientes: la carta de renuncia, el último recibo, entre otros. Y se pueden modificar los métodos de cálculo, así como generar y eliminar conceptos de cálculo. Nuestros ejecutivos de cuenta pueden llevar a cabo este proceso, o bien, si así lo desea, podemos brindar acceso para que su equipo se encargue de la creación de finiquitos.",
  },
  {
    img: showcaseReports,
    description:
      "Toda la información de la que disponemos en nuestro sistema de nóminas puede extraerse con parametrizaciones muy sencillas. Se pueden generar reportes para un grupo de empleados o para un empleado en particular. En el catálogo de conceptos se seleccionan las columnas que se quieren incluir en el reporte y este se puede generar, descargar o enviar en distintos formatos.",
  },
  {
    img: showcaseResult,
    description:
      "Una vez que se manda calcular la nómina, el resultado se muestra en forma de resumen, informa los montos que se van a pagar y por qué conceptos, y se puede profundizar en cada uno de ellos hasta llegar al nivel de lo que cada empleado percibirá. Aquí su equipo podrá revisar y autorizar el resultado para generar los reportes correspondientes y continuar con las siguientes etapas del proceso.",
  },
  {
    img: showcaseVacations,
    description:
      "Quiere la boca exhausta vid, kiwi, piña y fugaz jamón. Fabio me exige, sin tapujos, que añada cerveza al whisky. Jovencillo emponzoñado de whisky, ¡qué figurota exhibes! La cigüeña tocaba cada vez mejor el saxofón y el búho pedía kiwi y queso. El jefe buscó el éxtasis en un imprevisto baño de whisky y gozó como un duque.",
  },
  {
    img: showcaseIndicents,
    description:
      "El personal del área de Nóminas podrá acceder a este módulo para integrar y gestionar, en un contenedor dinámico, las incidencias del periodo actual. Desde el control de asistencia hasta los consumos del comedor, pasando por bonos, créditos, Infonavit e Infonacot, el usuario podrá visualir todos los registros, modificarlos si es necesario, eliminar y/o hasta crear nuevos. Para posteriormente transferir la información al módulo de cálculo.",
  },
];

const ModulDetail = ({}) => {
  const [showcase, setShowcase] = useState(0);
  const chooseCase = (e) => {
    setShowcase(e.target.value);
  };
  return (
    <>
      <ModulShowcase showcase={showcases[showcase].img}></ModulShowcase>
      <div className="modul-description">
        <Container>
          <div className="modul-description__dropdown-menu">
            <div className="custom-select">
              <select onChange={(e) => chooseCase(e)}>
                <option value={0}>BÓVEDA FISCAL</option>
                <option value={1}>VACACIONES</option>
                <option value={2}>INCAPACIDADES</option>
                <option value={3}>FINIQUITOS</option>
                <option value={4}>REPORTES</option>
                <option value={5}>RESULTADO</option>
                <option value={6}>INCIDENCIAS</option>
              </select>
              <span className="custom-arrow"></span>
            </div>
          </div>

          <div className="modul-description__body">
            {showcases[showcase].description}
          </div>
        </Container>
      </div>
    </>
  );
};

const Welcome = () => {
  return (
    <section className="integrity_welcome">
      <Container>
        <div className="title">
          <div>Bienvenidos a: </div>
          <div className="title__logo">
            <img
              src={integrityLogo}
              alt="Integrity Logo"
              className="img-fluid"
            ></img>
          </div>
        </div>
        <p className="description">
          iNTEGRITY es una herramienta web para nuestros ejecutivos de cuenta o
          personal del área de nóminas, RRHH o contabilidad de nuestros
          clientes. Su objetivo es facilitar las tareas relacionadas con el
          procesamiento de nóminas para estos tipos de usuarios. Poniendo a su
          disposición una serie de módulos súper capaces y muy sencillos de usar
          podrán gestionar información previo y posterior al cálculo de sus
          nóminas…
        </p>
      </Container>

      <div className="line-purple"></div>

      <Container>
        <p className="d-flex justify-content-center">
          Conozca los módulos básicos de la aplicación:
        </p>
      </Container>

      <ModulDetail />
    </section>
  );
};
export default Welcome;
