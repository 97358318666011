// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Navbar } from "../components-base";
import { Card } from "react-bootstrap";

// Assets.
import {
  IconSLoopSvg,
  IconHandSvg,
  IconKeySvg,
  IconGearSvg,
  IconForkSvg,
} from "./Assets";

const CardService = ({ icon, title, description, to }) => {
  return (
    <Card style={{ width: "12.5rem", height: "12.5rem" }}>
      <a href={to} className="link">
        <Card.Body>
          {icon}
          <h3>{title}</h3>
          <p>{description}</p>
        </Card.Body>
      </a>
    </Card>
  );
};

const Hero = () => {
  return (
    <header className="home_hero position-relative">
      <div className=" home_hero__bg "></div>
      <Navbar />
      <Container>
        <div className="text-center">
          <div className="d-flex flex-column align-items-center">
            <h1>Maquila de Nómina de Alto Rendimiento</h1>
            <h2>
              Para compañías globales <span>en México</span>
            </h2>
          </div>
        </div>
        <div className="d-flex justify-content-lg-between justify-content-center flex-xl-nowrap flex-wrap home_hero__card--margin gap-cards">
          <CardService
            title={"Maquila de Nómina"}
            description={"Procesamiento de nóminas de Alto Rendimiento"}
            icon={<IconSLoopSvg />}
            to={"./payroll-maquila"}
          />
          <CardService
            title={"Recibo de Nómina"}
            description={"Solución integral con múltiple valor agregado"}
            icon={<IconHandSvg />}
            to={"./cfdis"}
          />
          <CardService
            title={"My[Company]®"}
            description={"Atención personalizada a todos los empleados"}
            icon={<IconKeySvg />}
            to={"./my-company"}
          />
          <CardService
            title={"DICOMA®"}
            description={"Registro de consumos en el comedor de empleados"}
            icon={<IconForkSvg />}
            to={"./dicoma"}
          />
          <CardService
            title={"Software a la Medida"}
            description={"Automatización de procesos administrativos"}
            icon={<IconGearSvg />}
            to={"./software"}
          />
        </div>
      </Container>
    </header>
  );
};

export default Hero;
