import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

// Componenes CFDI's.
import { TitleGuard } from "./Titles";

//Asset.
import pageGuard from "./assets/img/guard.jpg";
import integrityGuard from "./assets/icons/integrity-logo.svg";
import myCompanyGuard from "./assets/icons/myCompany.svg";
import arrowIcon from "./assets/icons/arrow-right.svg";

const CardGuard = ({ img, width, height, title, description, to }) => {
  return (
    <div>
      <div className="d-flex align-items-center logos">
        <img
          src={img}
          alt="logo"
          width={width}
          height={height}
          className="img-fluid mb-3"
        />
      </div>
      <Card>
        <h5 className="mb-1">{title}</h5>
        <p>{description}</p>
        <a href={to} className="keep-reading">
          Conocer más
          <img src={arrowIcon} alt="Arrow right Icon" className="icon" />
        </a>
      </Card>
    </div>
  );
};
const Guard = () => {
  return (
    <section className="cfdis_guard">
      <Container>
        <TitleGuard />
        <img
          src={pageGuard}
          alt=""
          width={1080}
          height={600}
          className="img-fluid showcase"
        />
        <Row className="flex-wrap gap-5">
          <Col>
            <CardGuard
              img={integrityGuard}
              width={138}
              height={26}
              title={"Maquetación"}
              description="A través de nuestra aplicación iNTEGRITY, su equipo podrá gestionar de manera fácil y segura los CFDI’s de nómina de sus empleados…"
              to={"/integrity"}
            />
          </Col>
          <Col>
            <CardGuard
              img={myCompanyGuard}
              width={138}
              height={28}
              title={"Mis Recibos de Nómina"}
              description="A través de nuestra aplicación de auto-servicio sus empleados podrán consultar todo sus recibos de nómina, así como información derivaba de su relación laboral…"
              to={"/my-company"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Guard;
