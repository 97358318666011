// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Navbar } from "../components-base";

// Assets.

const Hero = () => {
  return (
    <>
      <Navbar typeNav="dark" />
      <Container>
        <section className="contact_hero">
          <h2>¡Hagamos contacto!</h2>
        </section>
      </Container>
    </>
  );
};
export default Hero;
