// Librerias.
import React from "react";

// Components Base.
import { Container, Row, Col } from "react-bootstrap";

// Assets.
import { OurMotivationSvg, OurMotivationMobileSvg } from "./Assets";

// Hooks.
import useWindow from "../hooks/useWindow";

const OurMotivationDesktop = () => {
  return (
    <section className="the-company_our-motivation">
      <Container className="d-flex flex-column flex-xl-nowrap">
        <Row>
          <Col className="the-company_our-motivation__title">
            Nuestra Motivación:
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col className="the-company_our-motivation__text">
            Ayudar a todos los miembros de nuestro equipo a desarrollarse,
            primero como personas, para garantizar su crecimiento profesional y
            financiero.
          </Col>
          <Col className="the-company_our-motivation__img">
            <OurMotivationSvg />
          </Col>
        </Row>
        <Row>
          <Col className="the-company_our-motivation__title d-flex justify-content-end flex-wrap text-center">
            Un mejor
            <br />
            país
          </Col>
        </Row>
      </Container>
      <div className="the-company_our-motivation__bg"></div>
    </section>
  );
};

const OurMotivationMobile = () => {
  return (
    <section className="the-company_our-motivation-mobile">
      <Container className="d-flex flex-row align-center">
        <Col>
          <Row className="the-company_our-motivation-mobile__title">
            Nuestra Motivación:
          </Row>
          <Row className="the-company_our-motivation-mobile__text">
            Ayudar a todos los miembros de nuestro equipo a desarrollarse,
            primero como personas, para garantizar su crecimiento profesional y
            financiero.
          </Row>
          <Row className="the-company_our-motivation-mobile__img img-fluid">
            <OurMotivationMobileSvg />
          </Row>
        </Col>
      </Container>
      <div className="the-company_our-motivation-mobile__bg"></div>
    </section>
  );
};

const OurMotivation = () => {
  const windowSize = useWindow();
  return (
    <>
      {windowSize.width > 768 ? (
        <OurMotivationDesktop />
      ) : (
        <OurMotivationMobile />
      )}
    </>
  );
};

export default OurMotivation;
