// Librerias.
import React from "react";
import {
    Container,
    Dropdown,
    Form
} from "react-bootstrap";
import {
    useHistory
} from "react-router-dom";

// Components Base.
import {
    Footer
} from "../components-base";

// Components Contact.
import ReturnToForms from "./ReturnToForms";
import Hero from "./Hero";
import {
    Sales
} from "./ApiCalls";

// Assets.
import sales from "./assets/img/sales.svg";

// Hooks.
import {
    useForm
} from "react-hook-form";

const PageContactSales = () => {
    const {
        register,
        formState: {
            errors
        },
        handleSubmit,
    } = useForm();

    const history = useHistory();

    const onSubmit = async(data, e) => {
        console.log(data);
        var formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("ubication", data.ubication);
        formData.append("company", data.company);
        formData.append("line", data.line);
        formData.append("role", data.role);
        formData.append("employees", data.employees);
        formData.append("maquila", data.maquila);
        formData.append("cfdis", data.cfdis);
        formData.append("software", data.software);
        formData.append("integrity", data.integrity);
        formData.append("my_company", data.my_company);
        formData.append("dicoma", data.dicoma);
        formData.append("comments", data.comments);
        const res = await Sales(formData);
        if (res.status === 200) {
            history.push("/contact/sales/success");
            e.target.reset();
        } else {
            history.push("/error");
        }
        //redireccionar hacia mensaje de exito
    };

    return ( <
        >
        <
        Hero / >
        <
        section className = "contact_sales" >
        <
        Container >
        <
        ReturnToForms to = {
            "../contact"
        }
        /> <
        div className = "wrapper" >
        <
        img src = {
            sales
        }
        alt = "Sales Icon"
        className = "icon" / >
        <
        h3 className = "" > ¿Desea contactar con nuestro equipo de ventas ? < /h3> <
        p className = "description" >
        Favor de compartirnos su información de contacto para poder atenderle… <
        /p> <
        form className = "form"
        onSubmit = {
            handleSubmit(onSubmit)
        } >
        <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "name" >
        Nombre completo <
        /label> <
        input type = "text"
        id = "name"
        name = "name" {...register("name", {
                required: {
                    value: true,
                    message: "El nombre es obligatorio",
                },
            })
        }
        /> <
        small className = "text-danger" > {
            errors.name && errors.name.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "email" >
        Correo electrónico <
        /label> <
        input type = "email"
        id = "email"
        name = "email" {...register("email", {
                required: {
                    value: true,
                    message: "El email es obligatorio",
                },
            })
        }
        />

        <
        small className = "text-danger" > {
            errors.email && errors.email.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "phone" >
        Teléfono <
        /label> <
        input type = "number"
        id = "phone"
        name = "phone" {...register("phone", {
                required: {
                    value: true,
                    message: "El teléfono es obligatorio",
                },
                minLength: {
                    value: 10,
                    message: "Favor de ingresar 10 dígitos",
                },
                maxLength: {
                    value: 10,
                    message: "Favor de ingresar solo 10 dígitos",
                },
            })
        }
        /> <
        small className = "text-danger" > {
            errors.phone && errors.phone.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "company" >
        Empresa <
        /label> <
        input type = "text"
        id = "company"
        name = "company" {...register("company", {
                required: {
                    value: true,
                    message: "La empresa es obligatoria",
                },
            })
        }
        /> <
        small className = "text-danger" > {
            errors.company && errors.company.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "role" >
        Puesto que desempeña <
        /label> <
        input type = "text"
        id = "role"
        name = "role" {...register("role", {
                required: {
                    value: true,
                    message: "El puesto es obligatorio",
                },
            })
        }
        /> <
        small className = "text-danger" > {
            errors.role && errors.role.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "line" >
        Giro de la empresa <
        /label> <
        input type = "text"
        id = "line"
        name = "line" {...register("line", {
                required: {
                    value: true,
                    message: "El giro es obligatorio",
                },
            })
        }
        /> <
        small className = "text-danger" > {
            errors.line && errors.line.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "ubication" >
        Ubicación de la empresa <
        /label> <
        div className = "form__dropdown-menu" >
        <
        div className = "custom-select" >
        <
        select id = "ubication"
        name = "ubication" {...register("ubication", {
                required: "La ubicación es obligatoria",
            })
        } >
        <
        option value = ""
        disabled selected >
        Selecciona una opción <
        /option>

        <
        option value = "Aguascalientes" > Aguascalientes < /option> <
        option value = "Baja California" > Baja California < /option> <
        option value = "Baja California Sur" >
        Baja California Sur <
        /option> <
        option value = "Campeche" > Campeche < /option> <
        option value = "Chiapas" > Chiapas < /option> <
        option value = "Chihuahua" > Chihuahua < /option> <
        option value = "Coahuila" > Coahuila < /option> <
        option value = "Colima" > Colima < /option> <
        option value = "Durango" > Durango < /option> <
        option value = "Guanajuato" > Guanajuato < /option> <
        option value = "Guerrero" > Guerrero < /option> <
        option value = "Hidalgo" > Hidalgo < /option> <
        option value = "Jalisco" > Jalisco < /option> <
        option value = "Estado de Mexico" > Estado de México < /option> <
        option value = "Michoacan" > Michoacán < /option> <
        option value = "Morelos" > Morelos < /option> <
        option value = "Nuevo Leon" > Nuevo León < /option> <
        option value = "Oaxaca" > Oaxaca < /option> <
        option value = "Puebla" > Puebla < /option> <
        option value = "Queretaro" > Querétaro < /option> <
        option value = "Quintana Roo" > Quintana Roo < /option> <
        option value = "San Luis Potosi" > San Luis Potosí < /option> <
        option value = "Sinaloa" > Sinaloa < /option> <
        option value = "Sonora" > Sonora < /option> <
        option value = "Tabasco" > Tabasco < /option> <
        option value = "Tamaulipas" > Tamaulipas < /option> <
        option value = "Tlaxcala" > Tlaxcala < /option> <
        option value = "Veracruz" > Veracruz < /option> <
        option value = "Yucatan" > Yucatán < /option> <
        option value = "Zacatecas" > Zacatecas < /option> <
        option value = "Otro" > Otro < /option> < /
        select > <
        span className = "custom-arrow" > < /span> < /
        div > <
        /div> <
        small className = "text-danger" > {
            errors.ubication && errors.ubication.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "employees" >
        Cantidad de empleados <
        /label> <
        div className = "form__dropdown-menu" >
        <
        div className = "custom-select" >
        <
        select id = "employees"
        name = "employees" {...register("employees", {
                required: "La cantidad de empleados es obligatoria",
            })
        } >
        <
        option value = ""
        disabled selected >
        Selecciona una opción <
        /option> <
        option value = "0 - 100" > 0 - 100 empleados < /option> <
        option value = "101 - 500" > 101 - 500 empleados < /option> <
        option value = "501 - 1000" > 501 - 1000 empleados < /option> <
        option value = "1001 - 2000" > 1001 - 2000 empleados < /option> <
        option value = "2001 - 3000" > 2001 - 3000 empleados < /option> <
        option value = "+3000" > Más de 3000 empleados < /option> < /
        select > <
        span className = "custom-arrow" > < /span> < /
        div > <
        /div> <
        small className = "text-danger" > {
            errors.employees && errors.employees.message
        } <
        /small> < /
        div > <
        div className = "form_group" >
        <
        label className = "form_group--label"
        htmlFor = "dropdown-autoclose-outside" >
        Servicio y / o producto de su interés <
        /label> <
        Dropdown autoClose = "outside" >
        <
        Dropdown.Toggle variant = "success"
        id = "dropdown-autoclose-outside"
        name = "multiselect" >
        Elije 1 ó varias opciones... <
        /Dropdown.Toggle> <
        Dropdown.Menu className = "p-3" >
        <
        Form.Check type = {
            "checkbox"
        }
        id = {
            `maquila`
        }
        label = {
            `Maquila de Nómina`
        }
        name = "maquila" {...register("maquila", {
                required: false,
            })
        }
        value = "INTERÉS" /
        >

        <
        Form.Check type = {
            "checkbox"
        }
        id = {
            `cfdis`
        }
        label = {
            `Gestión de CFDI'S`
        }
        name = "cfdis" {...register("cfdis", {
                required: false,
            })
        }
        value = "INTERÉS" /
        >

        <
        Form.Check type = {
            "checkbox"
        }
        id = {
            `software`
        }
        label = {
            `Desarrollo de Software`
        }
        name = "software" {...register("software", {
                required: false,
            })
        }
        value = "INTERÉS" /
        >

        <
        Form.Check type = {
            "checkbox"
        }
        id = {
            `integrity`
        }
        label = {
            `iNTEGRITY`
        }
        name = "integrity" {...register("integrity", {
                required: false,
            })
        }
        value = "INTERÉS" /
        >

        <
        Form.Check type = {
            "checkbox"
        }
        id = {
            `my_company`
        }
        label = {
            `My [Company]`
        }
        name = "my_company" {...register("my_company", {
                required: false,
            })
        }
        value = "INTERÉS" /
        >

        <
        Form.Check type = {
            "checkbox"
        }
        id = {
            `dicoma`
        }
        label = {
            `DICOMA`
        }
        name = "dicoma" {...register("dicoma", {
                required: false,
            })
        }
        value = "INTERÉS" /
        >
        <
        /Dropdown.Menu> < /
        Dropdown >

        { /* <small className="text-danger">*Se requiere minimo un Servicio y/o producto</small> */ } <
        /div> <
        div className = "form_group comments" >
        <
        label className = "form_group--label"
        htmlFor = "comments" >
        Comentarios <
        /label> <
        textarea placeholder = "Favor de describir brevemente sus necesidades…"
        id = "comments"
        name = "comments" {...register("comments", {
                required: {
                    value: true,
                    message: "Este campo es obligatorio",
                },
            })
        } >
        <
        /textarea> <
        small className = "text-danger" > {
            errors.comments && errors.comments.message
        } <
        /small> < /
        div >

        <
        button type = "submit"
        className = "btn-form" >
        ENVIAR <
        /button> < /
        form > <
        /div> < /
        Container > <
        /section> <
        Footer / >
        <
        />
    );
};
export default PageContactSales;