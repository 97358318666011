import React from "react";

import {
  CompassSvg,
  ExperienceSvg,
  StrategySvg,
  InterfaceSvg,
  SoftwareSvg,
  GearSvg,
} from "./assets/index.js";
const Title = ({ id, icon, title, description }) => {
  return (
    <div className="software_title" id={id}>
      <div className="d-flex align-items-center mb-4 title-phone">
        <div className="layout-icon">{icon}</div>
        <h2 className="mt-0">{title}</h2>
      </div>
      <p>{description}</p>
    </div>
  );
};

export const TitleStrategy = () => {
  return (
    <Title
      icon={<StrategySvg />}
      title="ESTRATEGIA"
      id="strategy"
      description="Detectamos el problema y cómo vamos a solucionarlo, con qué recursos deberá contar el cliente, tanto en cuestión de tiempo o personal, como en dinero, de manera que se pueda evaluar si la solución es viable o no. Hablamos con el cliente para escuchar cuál es su problema, analizar opciones, aterrizar una solución y elaborar un planteamiento."
    />
  );
};
export const TitleArchitecture = () => {
  return (
    <Title
      icon={<CompassSvg />}
      title="ARQUITECTURA"
      id="arquitecture"
      description="En esta etapa se define cómo estará compuesta la infraestructura que soporta la solución digital.
      La experiencia de nuestro equipo en el manejo de distintas herramientas de cloud computing nos permite ofrecer soluciones a la medida con el principal objetivo de garantizar la alta disponibilidad y continuidad del servicio, así como seguridad en todo momento de la información de nuestros clientes."
    />
  );
};
export const TitleUX = () => {
  return (
    <Title
      icon={<ExperienceSvg />}
      title="EXPERIENCIA DEL USUARIO (UX)"
      id="ux"
      description="La experiencia del usuario es el nivel de satisfacción de una persona al usar un determinado producto que le permite cumplir con ciertos objetivos. El personal de nóminas, es ya de por sí, gente que maneja mucho estrés y presión: queremos que por lo menos nuestras aplicaciones no les generen más estrés, sino que les faciliten la vida y el trabajo. "
    />
  );
};
export const TitleUI = () => {
  return (
    <Title
      icon={<InterfaceSvg />}
      title="INTERFAZ GRÁFICA (UI)"
      id="ui"
      description="La interfaz gráfica es el medio de interacción con el cliente y/o el usuario final. Por ello, dedicamos mucho tiempo y recursos a mejorar constantemente nuestros estándares de calidad en el diseño, siendo la belleza y funcionalidad de la composición visual de nuestras aplicaciones, nuestro principal diferenciador ante la competencia."
    />
  );
};
export const TitleSoftware = () => {
  return (
    <Title
      icon={<SoftwareSvg />}
      title="Software"
      id="software"
      description="Al terminar la etapa de diseño, se entrega el producto al equipo de programación. Para hacerlo garantizando la continuidad en la calidad de la experiencia del usuario, contamos con desarrolladores de software especializados de la más alta calidad, con experiencia en diferentes tipos de lenguaje, así como la integración de los mismos."
    />
  );
};
export const TitleProduction = () => {
  return (
    <Title
      icon={<GearSvg />}
      title="PRODUCCIÓN"
      id="production"
      description="En esta etapa se lanza la aplicación una vez terminada. La aplicación es un producto vivo y una vez lanzado puede haber actualizaciones, mantenimiento, ajustes, modificaciones y soporte, entre otros servicios, tal y como ocurre en el caso de los automóviles, por ejemplo."
    />
  );
};
