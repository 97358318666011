import React from "react";
import { Container } from "react-bootstrap";
//Asset.
import generalComputer from "./assets/img/general-computer.jpg";
import generalpeople from "./assets/img/general-people.png";

const General = () => {
  return (
    <section className="dicoma_general">
      <div className="text-center">
        <img
          src={generalComputer}
          alt="computer nomi"
          className="img-fluid img-computer"
        />
      </div>
      <Container>
        <h2>Consumos Generales</h2>
        <p>
          En esta parte de los consumos generales a través de iNTEGRITY,
          cualquier integrante del personal de RRH puede ingresar, consultar los
          consumos generales y generar reportes para los empleados, proveedores
          internos, aclaraciones o con cualquier otro fin legítimo.
        </p>
        <div className="text-center">
          <img src={generalpeople} alt="" className="img-fluid img-people" />
        </div>
      </Container>
    </section>
  );
};
export default General;
