// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import { DesignThinkingImg, DesignThinkingPhoneImg } from "./Assets";

// Hooks.
import useWindow from "../hooks/useWindow";

const Thinking = () => {
  const windowSize = useWindow();
  const showImage =
    windowSize.width > 500 ? (
      <DesignThinkingImg className="img-fluid" />
    ) : (
      <DesignThinkingPhoneImg className="img-fluid" />
    );
  return (
    <section className="approach_thinking">
      <Container>
        <h2>Design Thinking</h2>
        <p>
          Como verdaderos innovadores, nos basamos en marcos de pensamiento
          creativos no convencionales, pero eficaces, que nos proporcionan una
          visión diferente, más humana, sobre cómo mejorar o desarrollar
          productos digitales que se centran en los obstáculos reales del
          usuario, el contexto y las necesidades específicas…
        </p>
        {showImage}
      </Container>
    </section>
  );
};

export default Thinking;
