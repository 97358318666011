import React from "react";
import { Container } from "react-bootstrap";
//Asset.
import application from "./assets/img/application.png";
import calendar from "./assets/img/calendar.svg";

const Application = () => {
  return (
    <section className="dicoma_application">
      <Container>
        <div className="dicoma_application__info">
          <h1 className="description">
            La solución integral para el registro de consumos en su comedor
            industrial está servida.
          </h1>
          <div className="btn-dicoma">
            <img src={calendar} className="icon" alt="Calendar" />
            Solicite una DEMO
          </div>
        </div>
      </Container>
      <div className="dicoma_application__bg"></div>
      <img
        src={application}
        alt="Application BG"
        className="dicoma_application__bg-img img-fluid"
      />
    </section>
  );
};
export default Application;
