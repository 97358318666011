// Librerias.
import React from "react";
import { Container, Card } from "react-bootstrap";

// Components Base.
import { GradientBg, ReturnToServices } from "../components-base";

// Components Payrroll Maquila.
import { TitleIncidents } from "./Titles";


// Assets.
import validationImg from "./assets/img/incidents.svg";
import incidentsPhoneImg from "./assets/img/incidents-phone.svg";
import iconTime from "./assets/icons-incidents/time.svg";
import iconCup from "./assets/icons-incidents/cup.svg";
import iconBag from "./assets/icons-incidents/bag.svg";
import iconFork from "./assets/icons-incidents/fork.svg";
import iconFix from "./assets/icons-incidents/fix.svg";

// Hooks.
import useWindow from "../hooks/useWindow";

const IncidentsDesktop = () => {
  return (
    <div>
      <div className="mt-5">
        <img
          src={validationImg}
          alt="Incidencias"
          width={1080}
          height={1113}
          className=" img-fluid img-incidents"
        />
      </div>
    </div>
  );
};

const CardIncidents = ({ icon, title, description, children }) => {
  return (
    <Card>
      <Card.Body>
        <div>
          <div className="d-flex align-items-center">
            <span className="me-2">
              <img src={icon} alt="icon time" />
            </span>
            <h3>{title}</h3>
          </div>
          <p className="fw-bold">{description}</p>
        </div>
        <div>{children}</div>
      </Card.Body>
    </Card>
  );
};
const IncidentsPhone = () => {
  return (
    <div className="mt-4">
      <h2>Incidencias de Nómina</h2>
      <div className="text-center mt-4">
        <img src={incidentsPhoneImg} alt="" width="280" height="280" />
      </div>
      <div className="d-flex justify-content-center flex-column gap-3 mt-4">
        <CardIncidents
          icon={iconTime}
          title="CONTROL DE ASISTENCIA"
          description="Información registrada mediante el sistema de control de asistencia:"
        >
          <p className="fw-bold">-Tiempo regular</p>
          <p className="fw-bold">+ Tiempo No Regular</p>
          <p className="ps-3">- Tiempo Extra</p>
          <p className="ps-3">- Descanso Trabajado</p>
          <p className="fw-bold">+ Ausencias</p>
          <p className="ps-3">+ Justificadas</p>
          <p className="ps-5">- con goce de sueldo</p>
          <p className="ps-5">- sin goce de sueldo</p>
          <p className="fw-bold">- Incapacidades</p>
          <p className="fw-bold">- Vacaciones</p>
        </CardIncidents>
        <CardIncidents
          icon={iconCup}
          title="BONOS"
          description="Pagos adicionales al salario regular (fuera de los requeridos por ley)"
        />
        <CardIncidents
          icon={iconBag}
          title="CRÉDITOS"
          description="INFONAVIT, FONACOT y/u otros préstamos deducidos vía nómina"
        />
        <CardIncidents
          icon={iconFork}
          title="COMEDOR"
          description="Consumos de empleados en el comedor deducidos vía nómina"
        />
        <CardIncidents
          icon={iconFix}
          title="CORRECCIONES"
          description="Ajustes de periodos anteriores que son hechos en el actual"
        />
      </div>
    </div>
  );
};

const Incidents = () => {
  const windowSize = useWindow();
  return (
    <section className="payroll_maquila_incidents">
      <GradientBg
        className="gradient"
        direction="top left"
        gradientFrom="rgba(245, 245, 245, 1) 5%"
      >
        <Container>
          <TitleIncidents />
          {windowSize.width > 768 ? <IncidentsDesktop /> : <IncidentsPhone />}
          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default Incidents;
