// Librerias.
import React from "react";
import { Container } from "react-bootstrap";
// Components Base.
import Button from "./Button";
const Contact = ({ title }) => {
  return (
    <section className="contact">
      <div className="purple-line-mobile"></div>
      <Container className="text-center">
        <p className="mb-lg-4 mb-3">{title}</p>
        <h1 className="mb-lg-5 mb-3">¡Compruébelo usted mism@!</h1>
        <div className="d-flex justify-content-center">
          <Button to="/contact" width="260">
            Contáctenos
          </Button>
        </div>
      </Container>
    </section>
  );
};
export default Contact;
