// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import { PersonImg, WatchImg, WomanImg } from "./Assets";

const Step1 = () => {
  return (
    <>
      <div className="aproach_steps__1">
        <div className="aproach_steps__1--text-left">
          <h3>Conciencia</h3>
          <p>
            Trabajamos para compañías de Alto Rendimiento. Por lo que entendemos
            la importancia de la Alta Disponibilidad, no solo de nuestra
            infraestructura tecnológica, sino especialmente de nuestro soporte
            al cliente 24x7.
          </p>

          <p>
            El equipo NOMI está siempre atento y listo para atender cualquier
            situación respecto a su proceso de nómina, con el fin de ayudar a su
            equipo a superar cualquier obstáculo, esperado e inesperado, que
            pueda enfrentar.
          </p>
        </div>
        <WatchImg className="aproach_steps__1--img-right img-fluid" />
      </div>
    </>
  );
};
const Step2 = () => {
  return (
    <>
      <div className="aproach_steps__2">
        <div className="aproach_steps__2--text-right">
          <h3>Capacitación</h3>
          <p>
            Hemos desarrollado una cultura creativa orientada al servicio, donde
            la innovación y la excelente atención al cliente son los objetivos
            más importantes.
          </p>
          <p>
            Para mantener esto, no sólo instruimos a nuestro equipo sobre cómo
            generar ideas u ofrecer un servicio excelente, sino que también nos
            esforzamos por preservar un ambiente en el que las personas se
            sientan cómodas y comprometidas a hacerlo.
          </p>
        </div>
        <PersonImg className="aproach_steps__2--img-left img-fluid" />
      </div>
    </>
  );
};
const Step3 = () => {
  return (
    <>
      <div className="aproach_steps__3">
        <div className="aproach_steps__3--text-left">
          <h3>Mejora Continua</h3>
          <p>
            Porque estamos convencidos de que las personas sobresalientes crean
            equipos sobresalientes, y “un equipo sobresaliente equivale a un
            servicio extraordinario”…
          </p>
          <p>
            Damos a cada miembro la posibilidad de desarrollarse, personal y
            profesionalmente, para crecer dentro de la organización.
            Proporcionándoles las herramientas y la formación adecuadas para que
            logren sus propios objetivos a su propio ritmo.
          </p>
        </div>
        <WomanImg className="aproach_steps__3--img-right img-fluid" />
      </div>
    </>
  );
};
const Steps = () => {
  return (
    <>
      <div className="aproach_steps__wrapper">
        <div className="aproach_steps__wrapper--steps">
          <Container>
            <Step1 />
            <Step2 />
            <Step3 />
          </Container>
        </div>
      </div>
    </>
  );
};

export default Steps;
