// Librerias.
import React from "react";

// Assets.
import {
  CalculationSvg,
  CommunicationSvg,
  IncidentsSvg,
  InteractionSvg,
  Num1Svg,
  Num2Svg,
  Num3Svg,
  Num4Svg,
  Num5Svg,
  Num6Svg,
  Num7Svg,
  ParametersSvg,
  RetrospectiveSvg,
  SpecialProcessesSvg,
} from "./assets";

const Title = ({ id, iconNumber, iconService, title, description }) => {
  return (
    <div>
      <div className="payroll_maquila_title" id={id}>
        <div className="d-flex align-items-center gap-2">
          <div>{iconNumber}</div>
          <div>{iconService}</div>
        </div>
        <div>{title}</div>
      </div>
      <div className={`payroll_maquila_description`}>{description}</div>
    </div>
  );
};

export const TitleParameters = () => {
  return (
    <Title
      iconNumber={<Num1Svg />}
      iconService={<ParametersSvg />}
      title="PARÁMETROS"
      id="parameters"
      description={
        <>
          <p>
            Aquí comenzamos, cada periodo de nómina. Una configuración continua
            de nuestro sistema con datos fijos, pre-establecidos y/o dinámicos
            que se convertirá en la base para el cálculo de la nómina. Esta
            información se clasifica en diferentes grupos y etapas:
          </p>
          <ul className="fw-bold list-unstyled">
            <li>1.1 Implementación</li>
            <li>1.2 Actualización Anual</li>
            <li>1.3 Actualización Continua (cada periodo de nómina)</li>
          </ul>
        </>
      }
    />
  );
};
export const TitleInteraction = () => {
  return (
    <Title
      iconNumber={<Num2Svg />}
      iconService={<InteractionSvg />}
      title="INTERACCIÓN"
      id="interaction"
      description={
        <>
          <p>
            A través de módulos especializados dentro de nuestra app iNTEGRITY,
            nuestro ejecutivo de cuenta y su equipo podrán generar y/o
            administrar información de los empleados que después se convertirá
            en las incidencias del periodo o información de entrada para los
            procesos especiales…
          </p>
        </>
      }
    />
  );
};
export const TitleIncidents = () => {
  return (
    <Title
      iconNumber={<Num3Svg />}
      iconService={<IncidentsSvg />}
      title="INCIDENCIAS"
      id="incidents"
      description={
        <p>
          Esta información, que varía de un periodo a otro, se forma de la
          interacción entre diferentes áreas y proveedores, con la ayuda de
          aplicaciones como la de Registro de Asistencia o gestión de Consumos
          del comedor, entre otras. Y se convierte, junto con los Parámetros del
          periodo, en la base para determinar los salarios del mismo…
        </p>
      }
    />
  );
};
export const TitleCalculation = () => {
  return (
    <Title
      iconNumber={<Num4Svg />}
      iconService={<CalculationSvg />}
      title="CÁLCULO"
      id="calculation"
      description={
        <p>
          Una vez que recopilamos y validamos todos los parámetros e incidencias
          del periodo, la información está lista para ser procesada en nuestro
          sistema de nóminas en la nube, que estratégicamente corre sobre uno de
          los servidores de procesamiento de datos más seguros, estables y
          rápidos del mercado: <span>IBM-i Series</span>
        </p>
      }
    />
  );
};
export const TitleSpecialProcesses = () => {
  return (
    <Title
      iconNumber={<Num5Svg />}
      iconService={<SpecialProcessesSvg />}
      title="PROCESOS ESPECIALES"
      id="specialProcesses"
      description={
        <p>
          Son procesos, adicionales al cálculo de nómina, que se tienen que
          ejecutar por periodo, mensual o anualmente, conforme al cumplimiento
          de las responsabilidades fiscales o laborales. Así como el envío de
          toda la información necesaria al área de finanzas para cumplir con los
          requerimientos contables.
        </p>
      }
    />
  );
};
export const TitleCummunication = () => {
  return (
    <Title
      iconNumber={<Num6Svg />}
      iconService={<CommunicationSvg />}
      title="COMUNICACIÓN"
      id="communication"
      description={
        <p>
          Dado que generamos y gestionamos información privada de todos sus
          empleados, buscamos ser claros y comunicativos en todos nuestros
          procesos, con el fin de evitar las aclaraciones solicitadas al equipo
          de nóminas/RRHH y ayudarles a proporcionar una mejor experiencia de
          cliente interno hacia el resto de la empresa.
        </p>
      }
    />
  );
};

export const TitleRetrospective = () => {
  return (
    <Title
      iconNumber={<Num7Svg />}
      iconService={<RetrospectiveSvg />}
      title="RETROSPECTIVA"
      id="retrospective"
      description={
        <p className="ayroll_maquila_retrospective__description">
          Estamos determinados a mejorar nuestros procesos y experiencia de
          servicio, de un cálculo de nómina a otro. Por eso, después de cada
          periodo, nos reunimos en una sesión retrospectiva para evaluar y
          definir acciones de mejora específicas para el siguiente periodo.
        </p>
      }
    />
  );
};
