// Librerias.
import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

// Components Base.
import { Footer } from "../components-base";

// Components Contact.
import ReturnToForms from "./ReturnToForms";
import Hero from "./Hero";

// Assets.
import support from "./assets/img/support.svg";

// Hooks.
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Support } from "./ApiCalls";

const PageContactSupport = () => {
  registerLocale("es", es);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const history = useHistory();
  const [file, setFile] = useState({});

  const onSubmit = async (data, e) => {
    console.log(data);
    var formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("company", data.company);
    formData.append("app", data.app);
    formData.append("date", data.date);
    formData.append("file", file);
    formData.append("description", data.description);
    const res = await Support(formData);

    if (res.status === 200) {
      history.push("/contact/support/success");
      e.target.reset();
      setFile({});
    } else {
      history.push("/error");
    }
  };

  const handleFile = (e) => {
    const auxFile = e.target.files[0];
    setFile(auxFile);
  };

  return (
    <>
      <Hero />
      <section className="contact_support">
        <Container>
          <ReturnToForms to={"../contact"} />
          <div className="wrapper">
            <img src={support} alt="Support Icon" className="icon" />
            <h3 className="">¿Requiere Soporte Técnico?</h3>
            <p className="description">
              Favor de compartirnos información detallada para poder asistirle…
            </p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group">
                <label className="form_group--label" htmlFor="name">
                  Nombre Completo
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "El nombre es obligatorio",
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.name && errors.name.message}
                </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="company">
                  Empresa
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  {...register("company", {
                    required: {
                      value: true,
                      message: "La empresa es obligatoria",
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.company && errors.company.message}
                </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="email">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "El email es obligatorio",
                    },
                  })}
                />

                <small className="text-danger">
                  {errors.email && errors.email.message}
                </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="date">
                  Fecha del problema
                </label>
                <Form.Control
                  type="date"
                  min="2020-01-01"
                  max="2025-12-31"
                  id="date"
                  name="date"
                  control={control}
                  defaultValue={null}
                  {...register("date", {
                    required: {
                      value: true,
                      message: "La fecha es obligatoria",
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.date && errors.date.message}
                </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="app">
                  Aplicación
                </label>
                <div className="form__dropdown-menu">
                  <div className="custom-select">
                    <select
                      id="app"
                      name="app"
                      {...register("app", {
                        required: "La aplicación es obligatoria",
                      })}
                    >
                      <option value="" disabled selected>
                        Selecciona una opción
                      </option>
                      <option value="Maquila de Nomina">
                        Maquila de Nómina
                      </option>
                      <option value="Gestion de CFDIS">
                        Gestión de CFDI'S
                      </option>
                      <option value="Desarrollo de Software">
                        Desarrollo de Software
                      </option>
                      <option value="iNTEGRITY">iNTEGRITY</option>
                      <option value="My [Company]">My [Company]</option>
                      <option value="DiCOMA">DiCOMA</option>
                    </select>
                    <span className="custom-arrow"></span>
                  </div>
                </div>
                <small className="text-danger">
                  {errors.app && errors.app.message}
                </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="real-file">
                  Evidencia (foto de pantalla)
                </label>
                <div className="upload-btn">
                  <input
                    type="file"
                    id="real-file"
                    hidden="hidden"
                    name="file"
                    {...register("file", {
                      required: {
                        value: true,
                        message: "La evidencia es obligatoria",
                      },
                    })}
                    onChange={(e) => handleFile(e)}
                  />
                  <label id="custom-button" htmlFor="real-file">
                    Examinar...
                  </label>
                  <span id="custom-text">{file?.name}</span>
                </div>
                <small className="text-danger">
                  {errors.file && !file?.name && errors.file.message}
                </small>
              </div>
              <div className="form_group comments">
                <label className="form_group--label" htmlFor="description">
                  Descripción
                </label>
                <textarea
                  placeholder="Favor de describir detalladamente qué fue lo que sucedió…"
                  id="description"
                  name="description"
                  {...register("description", {
                    required: {
                      value: true,
                      message: "Este campo es obligatorio",
                    },
                  })}
                ></textarea>
                <small className="text-danger">
                  {errors.description && errors.description.message}
                </small>
              </div>

              <button type="submit" className="btn-form">
                ENVIAR
              </button>
            </form>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default PageContactSupport;
