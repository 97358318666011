// Librerias.
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Components Base.
import { GradientBg, ReturnToServices } from "../components-base";

// Components Payrroll Maquila.
import { TitleRetrospective } from "./Titles";

// Assets.
import retrospectiveImg from "./assets/img/restrospective-1.svg";
import retrospective2Img from "./assets/img/restrospective-2.svg";

const Restrospective = () => {
  return (
    <section className="payroll_maquila_retrospective">
      <GradientBg
        className="gradient"
        direction="top left"
        gradientFrom="rgba(245, 245, 245, 1) 5%"
      >
        <Container>
          <TitleRetrospective />
          <Row className="justify-content-between mt-5  ">
            <Col lg={3} className="">
              <div className="text-center">
                <img
                  src={retrospectiveImg}
                  alt="retrospectiva 1"
                  width={280}
                  height={184}
                  className="mb-3 img-fluid"
                />
              </div>
              <p className="payroll_maquila_retrospective__info mb-sm-3">
                Con un enfoque de servicio al cliente, dividimos cada periodo en
                3 fases y las analizamos para obtener acciones de mejora
                (output) para utilizarlas como input para el siguiente.
              </p>
            </Col>
            <Col lg={9} className="d-flex justify-content-end">
              <div>
                <img
                  src={retrospective2Img}
                  alt="retrospectiva 2"
                  width={750}
                  height={358}
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default Restrospective;
