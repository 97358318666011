// Librerias.
import React from "react";

// Assets.
import { ArrowReturnSvg } from "./Assets";

const ReturnToServices = ({ to = "#services" }) => {
  return (
    <div className="payroll_maquila_return-to-services">
      <a href={to} className="btn-return">
        <ArrowReturnSvg />
        <div>Regresar a Fases del Servicio</div>
      </a>
    </div>
  );
};

export default ReturnToServices;
