// Librerias.
import React from "react";
import { Container } from "react-bootstrap";
// Componenes CFDI's.
import { TitlePersonalization } from "./Titles";
//Asset.
import recibo from "./assets/img/recibo.png";
import reciboMobile from "./assets/img/recibo-mobile.png";

const Personalization = () => {
  return (
    <section className="cfdis_personalization">
      <Container>
        <TitlePersonalization />
      </Container>
      <div className="text-center">
        <img
          src={recibo}
          alt=""
          className="img-fluid img-desktop"
          width="1195"
          height="864"
        />
        <img
          src={reciboMobile}
          alt=""
          className="img-fluid img-mobile"
          width="1195"
          height="864"
        />
      </div>

      <Container>
        <div className="info-payroll-mobile">
          <div className="info">
            <div className="info__title">Experiencia del Usuario</div>
            <div className="info__descrption">
              Buscamos brindar al empleado una forma clara y agradable de
              comunicarle su información personal para evitar dudas y/o
              aclaraciones posteriores.
            </div>
          </div>
          <div className="info">
            <div className="info__title">Canal de Comunicación</div>
            <div className="info__descrption">
              Brindamos una herramienta de comunicación, gestionada por RRHH
              para comunicarse con los empleados de forma general o individual.
            </div>
          </div>
          <div className="info">
            <div className="info__title">Cumplimiento SAT</div>
            <div className="info__descrption">
              Cumplimos, conforme a la ley, con la emisión de certificados
              digitales por internet, así como el resguardo adecuado de los
              documentos emitidos.
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Personalization;
