// Librerias.
import React from "react";
// Components Base.
import { Footer, Contact } from "../components-base";

// Components Dicoma.
import Hero from "./Hero";
import Welcome from "./Welcome";
import Integration from "./Integration";
import Rules from "./Rules";
import Usability from "./Usability";
import Users from "./Users";

const PageIntegrity = () => {
  return (
    <>
      <Hero />
      <Welcome />
      <Integration />
      <Rules />
      <Usability />
      <Users />
      <Contact title="¿Desea proveer a su equipo con la mejor herramienta de gestión para cálculo de nóminas?" />
      <Footer />
    </>
  );
};

export default PageIntegrity;
