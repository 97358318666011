// Librerias.
import React, { useState } from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Footer } from "../components-base";

// Components Contact.
import ReturnToForms from "./ReturnToForms";
import Hero from "./Hero";

// Assets.
import talent from "./assets/img/talent.svg";

// Hooks.
import ScrollToTop from "../hooks/scrollToTop";

const PageContactTalentSuccess = () => {
  return (
    <>
      <ScrollToTop />
      <Hero />
      <section className="contact_success">
        <Container>
          <div className="wrapper">
            <img src={talent} alt="Talent Icon" className="icon img-fluid" />
            <p className="description">
              <span>
                ¡Muchas gracias por contactar con nuestro equipo de RRHH!
              </span>{" "}
              <br />
              En breve nos comunicaremos con usted…
            </p>
            <ReturnToForms to={"../../contact"}>Regresar</ReturnToForms>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default PageContactTalentSuccess;
