// Librerias.
import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

// Assets.
import quoteLeft from "./assets/img/quote-left.svg";

const Users = () => {
  return (
    <section className="integrity_users">
      <div className="integrity_users__wrapper">
        <Container>
          <h2 className="integrity_users__title">
            Usuarios felices para quienes tenemos el placer de trabajar:
          </h2>
          <img
            src={quoteLeft}
            alt="quote icon"
            className="my_company_users__icon-quote"
          />
          <Carousel variant="dark" controls={false}>
            <Carousel.Item>
              <p className="my_company_users__comment">
                Poder ingresar a mi histórico de recibos de nómina y enviar mis
                recibos a una dirección electrónica es algo tan práctico.
                Aprecio mucho esta función de mi aplicación MyCompany, hace
                mucho más sencillo cualquier trámite en el que deba presentar
                uno o varios recibos de nómina.
              </p>
              <div className="my_company_users__line" />
              <div className="my_company_users__person">
                <div className="my_company_users__person--name">
                  Adamari Sánchez
                </div>
                <div className="my_company_users__person--position">
                  Operaciones
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <p className="my_company_users__comment">
                Me facilita un trabajo que de otra manera resultaría en muchas
                tareas rutinarias. Desde el módulo de Bóveda fiscal puedo emitir
                recibos de distintos tipos de nómina, filtrar por período
                específico, validad si los certificados CFDI están activos ante
                el SAT y hacer envíos a los empleados. Esta app de NOMI llegó
                para quedarse; su sencillez y eficacia redundan en reducción del
                tiempo de trabajo y en mayor tranquilidad. iNTEGRITY es
                imprescindible en nuestra operación diaria.
              </p>
              <div className="integrity_users__line" />
              <div className="integrity_users__person">
                <div className="integrity_users__person--name">
                  Román Galvez
                </div>
                <div className="my_company_users__person--position">RRHH</div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <p className="integrity_users__comment">
                Me siento afortunado de contar con aplicaciones en las que puedo
                consultar mis recibos de nómina, mi saldo de vacaciones,
                incapacidades y calendario de trabajo, facilitan tanto la vida y
                liberan tiempo que podemos usar para asuntos personales y del
                trabajo.
              </p>
              <div className="integrity_users__line" />
              <div className="integrity_users__person">
                <div className="integrity_users__person--name">
                  Ulises Rangel
                </div>
                <div className="integrity_users__person--position">Ventas</div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <p className="integrity_users__comment">
                Ya no me imagino consumir en la cafetería de mi empresa sin la
                aplicación DICOMA. Desde mi teléfono puedo gestionar todos mis
                consumos con solo ingresar mi número de credencial, de empleado
                o mi código QR. En menos de un segundo sé si puedo consumir y a
                qué tipo de consumo tengo derecho. Cuando me descuentan por
                nómina el importe de mis consumos puedo verificar fechas y horas
                en que los realicé.
              </p>
              <div className="integrity_users__line" />
              <div className="integrity_users__person">
                <div className="integrity_users__person--name">
                  Carlos Macarena
                </div>
                <div className="integrity_users__person--position">
                  Línea de producción
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <p className="integrity_users__comment">
                My[Company] llegó a poner fin a la problemática de irse a formar
                a la ventanilla de RRHH y levantar una aclaración. Si me
                atendían bien y todo, pero nada quita la molestia de hacer fila,
                sobretodo los viernes que pagan, pues es cuando más reclamos hay
                y si llegas tarde, ya te toca buena fila…
              </p>
              <div className="integrity_users__line" />
              <div className="integrity_users__person">
                <div className="integrity_users__person--name">
                  Luisa Capistrán
                </div>
                <div className="integrity_users__person--position">
                  Línea de producción
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </Container>
      </div>
    </section>
  );
};
export default Users;
