// Librerias.
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Components Base.
import { Header } from "../components-base";

// Assets.
import heroPayrollMaquila from "./assets/img/hero-payroll-maquila.jpg";
import heroPayrollMaquilaPhone from "./assets/img/hero-payroll-maquila-phone.jpg";
import loopImg from "./assets/img/loop.svg";
import loopText from "./assets/img/loop-text.svg";

const Hero = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();
  const animText = useAnimation();

  useEffect(() => {
    console.log("use effect hook, inView = ", inView);
    if (inView) {
      animation.start({
        y: 280,
        scale: 0.6,
        transition: { duration: 0.7 },
      });
      animText.start({
        opacity: 1,
        scale: 0.98,
        transition: { delay: 0.3, duration: 0.7 },
      });
    }
    if (!inView) {
      animation.start({
        y: 0,
        scale: 1,
        transition: { duration: 0.7 },
      });
      animText.start({
        opacity: 0,
        scale: 1,
        transition: { duration: 0 },
      });
    }
  }, [inView]);
  return (
    <Header
      uri_bg_d={heroPayrollMaquila}
      uri_bg_p={heroPayrollMaquilaPhone}
      className="payroll_maquila_hero hero-img position-relative"
      height={1547}
    >
      <div className="hero-text">
        <h1 className="text-center">La complejidad es nuestra especialidad…</h1>
        <div className="d-flex justify-content-center">
          <h1 className="sub-title">
            Maquila de Nómina para grandes operaciones
          </h1>
        </div>
      </div>
      <motion.div className="grid-center" animate={animation}>
        <img
          src={loopImg}
          alt="loop services"
          width={1143}
          height={563}
          className="img-fluid loop-img"
        ></img>
        <motion.img
          src={loopText}
          alt="loop texts"
          className="img-fluid loop-text"
          width={1143}
          height={563}
          animate={animText}
        ></motion.img>
      </motion.div>
      <div ref={ref} className="referenceAnimationBox"></div>
    </Header>
  );
};
export default Hero;
