// Librerias.
import React from "react";

import { Container } from "react-bootstrap";

// Assets.
import ourCultureBgSvg from "./assets/svg/our-culture-bg.svg";
import ourCultureMobileBgSvg from "./assets/svg/our-culture-mobile-bg.svg";
import { CheckWhiteSvg } from "./Assets";

// Hooks.
import useWindow from "../hooks/useWindow";

const OurCultureDesktop = () => {
  return (
    <section className="the-company_our-culture the-company_our-culture__bg">
      <Container className="container__bg position-relative">
        <img
          src={ourCultureBgSvg}
          alt="Nuestra cultura"
          className="img-culture"
          width={1500}
          height={1512}
        />
        <div className="description">
          Creemos que una cultura centrada en valores humanos nos guiará a la
          excelencia en todas sus formas…
        </div>

        <div className="wrapper">
          <div className="wrapper__values">
            <div>
              <div className="wrapper__values--title-pink">
                NUESTROS VALORES:
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">OSADÍA</div>
              <div>
                Tenemos el valor de atrevernos a pensar y hacer las cosas de
                forma diferente.
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">JUSTICIA</div>
              <div>
                Nos enorgullece actuar, siempre, con honestidad e imparcialidad,
                sin vacilaciones.
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">COMPROMISO</div>
              <div>
                Estamos orientados y determinados a entregar resultados.
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const OurCultureMobile = () => {
  return (
    <section className="the-company_our-culture_mobile the-company_our-culture_mobile___bg">
      <img
        src={ourCultureMobileBgSvg}
        alt="Nuestra cultura"
        className="img-culture img-fluid"
        width={768}
        height={2018}
      />
      <Container className="container__bg position-relative">
        <div className="container__description">
          Creemos que una cultura centrada en valores humanos nos guiará a la
          excelencia en todas sus formas…
        </div>

        <div className="wrapper wrapper__bg">
          <div className="wrapper__values">
            <div>
              <div className="wrapper__values--title-pink">
                NUESTROS VALORES:
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <CheckWhiteSvg />
                DESCARO
              </div>
              <div>
                Tenemos el valor de atrevernos a pensar y hacer las cosas de
                forma diferente.
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <CheckWhiteSvg />
                JUSTICIA
              </div>
              <div>
                Nos enorgullece actuar, siempre, con honestidad y justicia, sin
                vacilaciones.
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <CheckWhiteSvg />
                COMPROMISO
              </div>
              <div>
                Estamos orientados y determinados a entregar resultados.
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const OurCulture = () => {
  const windowSize = useWindow();
  return (
    <>{windowSize.width > 768 ? <OurCultureDesktop /> : <OurCultureMobile />}</>
  );
};

export default OurCulture;
