// Librerias.
import React from "react";
// Components Base.
import { Footer, Contact } from "../components-base";

// Components Dicoma.
import Hero from "./Hero";
import Bpo from "./Bpo";
import Personalization from "./Personalization";
import Dispersion from "./Dispersion";
import Guard from "./Guard";

const PageCfdis = () => {
  return (
    <>
      <Hero />
      <Bpo />
      <Personalization />
      <Dispersion />
      <Guard />
      <Contact title="¿Desea automatizar procesos manuales en la gestión administrativa de su capital humano?" />
      <Footer />
    </>
  );
};

export default PageCfdis;
