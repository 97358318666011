// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Card } from "react-bootstrap";

// Assets.
import {
  IconOurGoalSvg,
  IconOurGoalMobile1Svg,
  IconOurGoalMobile2Svg,
  IllustVisionSvg,
  IllustMissionSvg,
} from "./Assets";

// Hooks.
import useWindow from "../hooks/useWindow";

const CardService = ({ icon, title, description }) => {
  return (
    <Card
      style={{ width: "18.75rem" }}
      className="mb-lg-0 me-lg-0 me-md-5 me-0 me-sm-0 mb-5"
    >
      <Card.Body>
        {icon}
        <h3>{title}</h3>
        {description}
      </Card.Body>
    </Card>
  );
};

const OurGoalDesktop = () => {
  return (
    <section className="the-company_our-goal">
      <Container>
        <div className="the-company_our-goal__icon--circle">
          <IconOurGoalSvg />
        </div>
        <div className="d-flex justify-content-lg-between justify-content-center flex-xl-nowrap flex-wrap ">
          <CardService
            icon={<IllustVisionSvg />}
            title={<h3>Visión</h3>}
            description={
              <p>
                Ser un factor decisivo para que las compañías globales decidan
                invertir en México, antes que en otros países.
              </p>
            }
          />
          <CardService
            icon={<IllustMissionSvg />}
            title={<h3>Misión</h3>}
            description={
              <p>
                Brindar pensamiento creativo, procesos y tecnología que agilicen
                la administración de compañías globales en México.
              </p>
            }
          />
        </div>
      </Container>
    </section>
  );
};

const OurGoalMobile = () => {
  return (
    <section className="the-company_our-goal">
      <Container>
        <div className="d-flex flex-column justify-content-center align-items-center flex-wrap">
          <IconOurGoalMobile1Svg />
          <CardService
            icon={<IllustVisionSvg />}
            title={<h3>Visión</h3>}
            description={
              <p>
                Ser un factor decisivo para que las compañías globales decidan
                invertir en México, antes que en otros países.
              </p>
            }
          />
          <IconOurGoalMobile2Svg />
          <CardService
            icon={<IllustMissionSvg />}
            title={<h3>Misión</h3>}
            description={
              <p>
                Brindar pensamiento creativo, procesos y tecnología que agilicen
                la administración de compañías globales en México.
              </p>
            }
          />
        </div>
      </Container>
    </section>
  );
};

const OurGoal = () => {
  const windowSize = useWindow();
  return <>{windowSize.width > 991 ? <OurGoalDesktop /> : <OurGoalMobile />}</>;
};

export default OurGoal;
