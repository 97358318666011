// Librerias.
import React from "react";
import { Container } from "react-bootstrap";
import { a } from "react-router-dom";

// Components Base.
import { GradientBg, ReturnToServices } from "../components-base";

// Components Payrroll Maquila.
import { TitleInteraction } from "./Titles";

// Assets.
import {
  LogoIntegritySvg,
  LogoDicomaSvg,
  VacationsSvg,
  DisabilitiesSvg,
  SettlementsSvg,
  DinnerRoomSvg,
} from "./assets";

const CardInteraction = ({ icon, title, description, children }) => {
  return (
    <div className="card">
      <div className="card__icon">{icon}</div>
      <div className="card__title">{title}</div>
      <div className="card__description">{description}</div>
      {children}
    </div>
  );
};

const Interaction = () => {
  return (
    <section className="payroll_maquila_interaction">
      <GradientBg className="gradient" gradientFrom="rgba(245, 245, 245, 1) 5%">
        <Container>
          <TitleInteraction />

          <div className="slider margin img-fluid">
            <div className="slide1 img-fluid"></div>
            <div className="slide2 img-fluid"></div>
            <div className="slide3 img-fluid"></div>
            <div className="slide4 img-fluid"></div>
            <div className="slide5 img-fluid"></div>
          </div>

          <div className="description margin">
            <LogoIntegritySvg />
            <p>
              Es una aplicación web que da soporte a los miembros de
              RRHH/Nóminas y Finanzas o cualquier otro usuario involucrado en el
              proceso de la nómina. Los siguientes son algunos de sus módulos
              que facilitan el flujo de datos, reduciendo las labores manuales y
              haciendo que su proceso sea más fluido:
            </p>
          </div>

          <div className="cards margin">
            <CardInteraction
              icon={<VacationsSvg />}
              title="VACACIONES Y PERMISOS"
              description="El supervisor recibirá una notificación, vía correo, cada vez que un empleado solicite vacaciones u otro permiso. Y podrá acceder a este módulo para autorizar o rechazar esta petición. En caso de autorizar, la información *se transfiere al sistema de registro de asistencia, para ser convertirse en una incidencia. En caso de rechazar podrá especificar un motivo. Sea cual sea la respuesta, el empleado la recibirá en su correo electrónico."
            ></CardInteraction>

            <CardInteraction
              icon={<DisabilitiesSvg />}
              title="INCAPACIDADES"
              description="El personal de Servicio Médico podrá acceder a este módulo para capturar las incapacidades del periodo. Ingresando los siguientes datos: No. Asociado, Tipo Incapacidad, Fecha de inicio, Días otorgados, Folio y Documento adjunto (foto). La información registrada en este módulo es *transferida automáticamente al sistema de registro de asistencia para convertirse en una incidencia de nómina en el actual periodo. "
            ></CardInteraction>

            <CardInteraction
              icon={<SettlementsSvg />}
              title="FINIQUITOS"
              description="El personal del área de Nóminas / RRHH podrá acceder a este módulo para solicitar la Baja de algún Asociado, ingresando los siguientes datos: No. Asociado, Tipo de baja (renuncia, despido…), fecha de terminación. Y, con base en la fecha de ingreso y otras variables, se calculará el monto del finiquito y se genera automáticamente la carta renuncia y/o cualquier otro documento necesario."
            ></CardInteraction>

            <CardInteraction
              icon={<DinnerRoomSvg />}
              title="COMEDOR"
              description="Nuestra aplicación para la Gestión del Comedor permite registrar, con distintos métodos de validación, los consumos de los empleados, proveedores e invitados. Su sincronización con iNTEGRITY, permite a los miembros de RRHH / Nóminas obtener reportes de consumo para deducción vía nómina y/o pago a proveedores."
            >
              <div className="card__box-purple">
                <div className="card__box-purple--left-align">
                  <LogoDicomaSvg />
                </div>
                <p className="card__box-purple--margin-top">
                  Para más información sobre nuestra aplicación para la gestión
                  de consumos:{" "}
                  <a href="dicoma" className="dicoma-link">
                    PULSE AQUÍ
                  </a>
                </p>
              </div>
            </CardInteraction>
          </div>

          <div className="asterisk margin">
            *Información disponible para consumo via API
          </div>

          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default Interaction;
