// Librerias.
import React from "react";

// Components Base.
import { Container } from "react-bootstrap";
import { Button } from "../components-base";

// Assets.
import { IconArrowSvg, HowBg, HowBgMobile } from "./Assets";

const How = () => {
  return (
    <section className="home_how">
      <Container>
        <div className="home_how__info">
          <h1 className="title">Cómo lo hacemos…</h1>
          <p className="description">
            Una extraordinaria experiencia para nuestros clientes externos
            depende de qué tan extraordinaria es para nuestros clientes
            internos.
          </p>
          <Button to="/approach" width="320" className="btn btn-desktop">
            Nuestro Approach
            <span>
              <IconArrowSvg className="icon-arrow"></IconArrowSvg>
            </span>
          </Button>
          <Button to="/approach" className="btn btn-mobile">
            Nuestro Approach
            <span>
              <IconArrowSvg className="icon-arrow"></IconArrowSvg>
            </span>
          </Button>
        </div>
      </Container>
      <div className="home_how__bg"></div>
      <HowBg className="home_how__bg-img img-fluid" />
      <HowBgMobile className="home_how__bg-mobile-img img-fluid" />
    </section>
  );
};

export default How;
