// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Base.
import { Navbar } from "../components-base";

// Assets.

const Hero = () => {
  return (
    <header className="the_company_hero the_company_hero__bg ">
      <Navbar />
      <Container>
        <h1>La Empresa</h1>
      </Container>
    </header>
  );
};

export default Hero;
