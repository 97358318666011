// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Components Software.
import { TitleStrategy } from "./Titles";
import ReturnToServices from "../components-base/ReturnToServices";

// Assets.
import { AnalysisSvg, RequestSvg, SolutionSvg } from "./assets/index.js";

const CardStrategy = ({ icon, title, children }) => {
  return (
    <div className="card">
      <div className="card__icon">{icon}</div>
      <div className="card__title">{title}</div>
      <div className="card__text">{children}</div>
    </div>
  );
};

const Strategy = () => {
  return (
    <section className="software_strategy">
      <Container>
        <TitleStrategy />
        <div className="cards margin">
          <CardStrategy icon={<AnalysisSvg />} title="1. Análisis del proceso">
            <div>
              Definir el problema o error específicos y sus consecuencias,
              evaluando mediante conversaciones presenciales o virtuales con los
              distintos involucrados, clientes y usuarios según sea necesario.
            </div>
          </CardStrategy>
          <CardStrategy icon={<RequestSvg />} title="2. Requerimiento">
            <div>
              Una vez definido el problema, se crea un BRD, el documento en el
              que se establece formalmente el producto o servicio que se va a
              desarrollar. Tanto el cliente como nosotros podemos generar este
              documento.
            </div>
          </CardStrategy>
          <CardStrategy icon={<SolutionSvg />} title="3. Solución">
            <div>
              La solución es nuestra cotización, nuestra propuesta. Elaboramos
              presentaciones: hacemos el prototipo para que el cliente lo vea y
              tenga una idea visual de cuál será la solución, así como los
              tiempos y costos de la misma.
            </div>
          </CardStrategy>
        </div>
        <ReturnToServices />
      </Container>
      <div className="line"></div>
    </section>
  );
};
export default Strategy;
