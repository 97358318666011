// Librerias.
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";

// Components Base.
import { Footer } from "../components-base";

// Components Contact.
import ReturnToForms from "./ReturnToForms";
import Hero from "./Hero";

// Assets.
import talent from "./assets/img/talent.svg";

// Hooks.
import { useForm } from "react-hook-form";
import { Job } from "./ApiCalls";

const PageContactTalent = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const history = useHistory();

  const onSubmit = async (data, e) => {
    console.log(data);
    var formData = new FormData();
    formData.append("name", data.name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("area", data.area);
    formData.append("work1", data.work1);
    formData.append("work2", data.work2);
    formData.append("work3", data.work3);

    formData.append("file", file);
    const res = await Job(formData);
    if (res.status === 200) {
      history.push("/contact/talent/success");
      e.target.reset();
      setFile({});
    } else {
      history.push("/error");
    }
  };

  const [file, setFile] = useState({});
  const handleFile = (e) => {
    const auxFile = e.target.files[0];
    setFile(auxFile);
  };
  return (
    <>
      <Hero />
      <section className="contact_talent">
        <Container>
          <ReturnToForms to={"../contact"} />
          <div className="wrapper">
            <img src={talent} alt="Sales Icon" className="icon" />
            <h3 className="">¿Desea trabajar en NOMI?</h3>
            <p className="description">Háblenos un poco de usted...</p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group column--1">
                <label className="form_group--label" htmlFor="name">
                  Nombre(s)
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "El nombre es obligatorio",
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.name && errors.name.message}
                </small>
              </div>
              <div className="form_group column--2">
                <label className="form_group--label" htmlFor="last_name">
                  Apellidos
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  {...register("last_name", {
                    required: {
                      value: true,
                      message: "Los apellidos son obligatorios",
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.last_name && errors.last_name.message}
                </small>
              </div>
              <div className="form_group column--3">
                <label className="form_group--label" htmlFor="email">
                  Correo electrónico
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "El email es obligatorio",
                    },
                  })}
                />

                <small className="text-danger">
                  {errors.email && errors.email.message}
                </small>
              </div>
              <div className="form_group column--1">
                <label className="form_group--label" htmlFor="phone">
                  Teléfono
                </label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "El teléfono es obligatorio",
                    },
                    minLength: {
                      value: 10,
                      message: "Favor de ingresar 10 dígitos",
                    },
                    maxLength: {
                      value: 10,
                      message: "Favor de ingresar solo 10 dígitos",
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.phone && errors.phone.message}
                </small>
              </div>
              <div className="form_group column--2">
                <label className="form_group--label" htmlFor="area">
                  Área de desempeño
                </label>
                <div className="form__dropdown-menu">
                  <div className="custom-select">
                    <select
                      id="area"
                      name="area"
                      {...register("area", {
                        required: "El area es obligatorio",
                      })}
                    >
                      <option value="" disabled selected>
                        Selecciona una opción
                      </option>
                      <option value="Administracion">Administración</option>
                      <option value="Diseño">Diseño</option>
                      <option value="Desarrollo (TI)">Desarrollo (TI)</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Nóminas">Nóminas</option>
                      <option value="Ventas">Ventas</option>
                    </select>
                    <span className="custom-arrow"></span>
                  </div>
                </div>
                <small className="text-danger">
                  {errors.area && errors.area.message}
                </small>
              </div>
              <div className="form_group column--3">
                <label className="form_group--label" htmlFor="real-file">
                  Curriculum Vitae
                </label>
                <div className="upload-btn">
                  <input
                    type="file"
                    id="real-file"
                    hidden="hidden"
                    name="file"
                    {...register("file", {
                      required: {
                        value: true,
                        message: "Este CV es obligatorio",
                      },
                    })}
                    onChange={(e) => handleFile(e)}
                  />
                  <label id="custom-button" htmlFor="real-file">
                    Examinar...
                  </label>
                  <span id="custom-text">{file?.name}</span>
                </div>
                <small className="text-danger">
                  {errors.file && !file?.name && errors.file.message}
                </small>
              </div>
              <div className="form_group comments--1">
                <label className="form_group--label" htmlFor="work">
                  Describa el trabajo ideal
                </label>
                <textarea
                  rows="2"
                  id="work1"
                  name="work1"
                  {...register("work1", {
                    required: {
                      value: true,
                      message: "Este campo es obligatorio",
                    },
                  })}
                ></textarea>
                <small className="text-danger">
                  {errors.work1 && errors.work1.message}
                </small>
              </div>
              <div className="form_group comments--2">
                <label className="form_group--label" htmlFor="work2">
                  Describa el trabajador ideal
                </label>
                <textarea
                  rows="2"
                  id="work2"
                  name="work2"
                  {...register("work2", {
                    required: {
                      value: true,
                      message: "Este campo es obligatorio",
                    },
                  })}
                ></textarea>
                <small className="text-danger">
                  {errors.work2 && errors.work2.message}
                </small>
              </div>
              <div className="form_group comments--3">
                <label className="form_group--label" htmlFor="work3">
                  ¿Por qué desea trabajar en NOMI?
                </label>
                <textarea
                  rows="2"
                  id="work3"
                  name="work3"
                  {...register("work3", {
                    required: {
                      value: true,
                      message: "Este campo es obligatorio",
                    },
                  })}
                ></textarea>
                <small className="text-danger">
                  {errors.work3 && errors.work3.message}
                </small>
              </div>

              <button type="submit" className="btn-form">
                ENVIAR
              </button>
            </form>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default PageContactTalent;
