// Librerias.
import React from "react";

// Assets.
import ReturnSvg from "./assets/img/return.svg";

const ReturnToForms = ({ children, to }) => {
  return (
    <div className="contact_return">
      <a href={to} className="">
        <img src={ReturnSvg} alt="asdfasdf" />
        {children}
      </a>
    </div>
  );
};

export default ReturnToForms;
