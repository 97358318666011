// Livrerias
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReturnToServices from "../components-base/ReturnToServices";
import { TitleSoftware } from "./Titles";

// Assets.
import {
  DataBaseSvg,
  ImgDataBaseSvg,
  IntegrationsSvg,
  ImgIntegrationsSvg,
  WebAppSvg,
  ImgWebAppSvg,
  ArrowsSvg,
  ArrowsMobileSvg,
} from "./assets/index.js";

const CardSoftware = ({
  icon,
  title,
  description,
  imgSoftware,
  children,
  classChildren,
}) => {
  return (
    <div className="wrapper">
      <div className="description row">
        <Col className="description__text mb-4">
          <div className="description__text--title mb-4">
            <span>{icon}</span>
            <h3>{title}</h3>
          </div>
          <div className="description__text--body">{description}</div>
        </Col>
        <Col className="description__img">{imgSoftware}</Col>
      </div>
      <div className={classChildren}>{children}</div>
    </div>
  );
};

const CardDetail = ({ children, classCard = "" }) => {
  return <div className={`cards ${classCard}`}>{children}</div>;
};

const Software = () => {
  return (
    <section className="software_software">
      <Container>
        <TitleSoftware />
        <CardSoftware
          icon={<WebAppSvg />}
          title="Aplicaciones Web"
          description="Nuestras aplicaciones web facilitan el acceso desde una computadora o dispositivo con internet. Esto también permite la actualización del producto, sin necesidad de que el usuario instale nuevas versiones."
          imgSoftware={<ImgWebAppSvg />}
          classChildren="web"
        >
          <div>
            <h2>FRONT-END</h2>
            <CardDetail classCard="web-card">
              <h4>Maquetación</h4>
              <p>
                La maquetación es darle vida al prototipo, ya que permite verlo
                en los navegadores debido a su programación HTML, uso de hojas
                de estilo (css, java…), frameworks y entre otros.
              </p>
              <h4>Conexión a Servicios</h4>
              <p>
                La programación del ‘front’ (previamente conocido como
                prototipo) debe considerar la creación de entradas y salidas
                necesarias para el intercambio de información con el ‘back’.
              </p>
            </CardDetail>
          </div>

          <div className="icon">
            <ArrowsSvg />
          </div>

          <div className="icon-mobile">
            <ArrowsMobileSvg />
          </div>

          <div>
            <h2>BACK-END</h2>
            <CardDetail classCard="web-card">
              <h4>Servicios (end-points)</h4>
              <p>
                Los endpoints son las URLs de un API o un backend que responden
                a una petición hecha desde el frontend. Es el punto inicial de
                comunicación entre estas dos instancias.
              </p>
              <h4>Lógica de Negocio</h4>
              <p>
                Es la parte del sistema que se encarga de codificar las reglas
                de negocio del mundo real que determinan cómo la información
                puede ser creada, almacenada y cambiada.
              </p>
              <h4>Conexión a Base de Datos</h4>
              <p>
                Nuestras aplicaciones permiten el intercambio de información en
                tiempo real entre el usuario final y los registros almacenados
                en nuestras bases de datos.
              </p>
            </CardDetail>
          </div>
        </CardSoftware>
        <CardSoftware
          icon={<IntegrationsSvg />}
          title={"Integraciones"}
          description="Todas nuestras aplicaciones pueden conectarse entre sí. También nos comunicamos con otros sistemas de nuestros clientes o de sus proveedores para enriquecer la oferta de la aplicación en desarrollo."
          imgSoftware={<ImgIntegrationsSvg />}
        >
          <Row>
            <Col>
              <CardDetail>
                <h4>API REST</h4>
                <p>
                  Por sus siglas en inglés “Application Programming Interface”,
                  y en español, “Interfaz de Programación de Aplicaciones”. Es
                  la forma en que dos aplicaciones o servicios se comunican
                  entre sí. … Por eso, estas sirven para que una aplicación
                  pueda interactuar con otra.
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail>
                <h4>SFTP</h4>
                <p>
                  Facilitamos la transferencias de archivos servidor-a-servidor
                  entre nosotros y el cliente, sus proveedores, y servidores
                  empresariales mediante SFTP (Secure File Transfer Protocol)
                  que es una opción que garantiza la seguridad de su información
                  y la de sus empleados.
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail>
                <h4>Microservicios</h4>
                <p>
                  Un enfoque arquitectónico y organizativo para el desarrollo de
                  software donde el software está compuesto por pequeños
                  servicios independientes que se comunican a través de API bien
                  definidas. Los propietarios de estos servicios son equipos
                  pequeños independientes.
                </p>
              </CardDetail>
            </Col>
          </Row>
        </CardSoftware>
        <CardSoftware
          icon={<DataBaseSvg />}
          title={"Bases de datos"}
          description="Dado que manejamos grandes cantidades de información sensible de sus empleados, mantenemos una sana gestión para salvaguardar los registros de su información generada por nuestras aplicaciones."
          imgSoftware={<ImgDataBaseSvg />}
        >
          <Row className="flex-wrap">
            <Col>
              <CardDetail>
                <h4>Mapeo de datos</h4>
                <p>
                  El mapeo de datos es el proceso por el que se establecen
                  correspondencias entre campos de una base de datos a otra. Es
                  el primer paso para facilitar la migración, integración y
                  otras tareas de gestión de datos.
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail>
                <h4>Normalización de Datos</h4>
                <p>
                  El proceso de organización de datos en una base de datos. Esto
                  incluye crear tablas y establecer relaciones entre dichas
                  tablas de acuerdo con reglas diseñadas tanto para proteger los
                  datos como para que la base de datos sea más flexible al
                  eliminar la redundancia y la dependencia incoherente.
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail className="mb-0">
                <h4>Análisis de datos</h4>
                <p>
                  El análisis de datos es el estudio exhaustivo de un conjunto
                  de información cuyo objetivo es obtener conclusiones que
                  permitan a una empresa o entidad tomar una decisión. … Durante
                  este análisis, los datos pueden ser objeto de operaciones, por
                  ejemplo, para obtener indicadores estadísticos.
                </p>
              </CardDetail>
            </Col>
          </Row>
        </CardSoftware>
        <ReturnToServices />
      </Container>
      <div className="line"></div>
    </section>
  );
};
export default Software;
