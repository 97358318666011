import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//Asset.
import checkin from "./assets/img/checkin.gif";
import manager from "./assets/img/manager.png";
import empleado from "./assets/img/empleado.png";

const CardDicoma = ({
  title,
  img,
  width = 318,
  height = 318,
  subtitle,
  description,
  classSubtitle = "",
}) => {
  return (
    <div className={"card"}>
      <h2>{title}</h2>
      <div>
        <img
          src={img}
          alt=""
          width={width}
          height={height}
          className="img-fluid"
        />
      </div>
      <div>
        <h4 className={classSubtitle}>{subtitle}</h4>
        <>{description}</>
      </div>
    </div>
  );
};

const Manager = () => {
  return (
    <section className="dicoma_manager">
      <Container>
        <Row>
          <Col lg={3} className="px-0 ">
            <CardDicoma
              title={"Manager"}
              img={manager}
              subtitle={"Todos los Consumos"}
              description={
                "El área de RRHH podrá consultar los registros generales de empleados, proveedores y/o invitados."
              }
            />
          </Col>
          <Col lg={6} className="px-0 order-0">
            <CardDicoma
              title={"Check-in"}
              img={checkin}
              subtitle={"Cuando el empleado hace Check-in…"}
              description={
                <>
                  <p>1… sistema recibe una PETICIÓN,</p>
                  <p>2… VALIDA según las reglas de negocio,</p>
                  <p>3… regresa una RESPUESTA,</p>
                  <button className="button-dicoma">4. REGISTRA</button>
                </>
              }
              width={442}
              height={430}
              classSubtitle="sub-title-middle "
            />
          </Col>
          <Col lg={3} className="px-0 order-3">
            <CardDicoma
              title={"Empleado"}
              img={empleado}
              subtitle={"Mis Consumos"}
              description={
                "El empleado podrá consultar sus consumos mediante su acceso a la aplicación de auto-servicio: My[Company]"
              }
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Manager;
