// Librerias.
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

// Components Base.
import { Button } from "../components-base";
// Assets.
import { LoopImg, OptimizationImg } from "./Assets";
const Approach = () => {
  return (
    <section className="approach_our">
      <Container>
        <h2>
          Descubra cómo nuestro ‘approach’ se ve reflejado en lo que hacemos…
        </h2>
        <Row className="justify-content-between flex-wrap">
          <Col lg={6} className="mb-5 mb-lg-0">
            <Card>
              <div className="card-body-payrroll ">
                <div className="text-center">
                  <LoopImg className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center">
                  <Button to={"/payroll-maquila"} width="280">
                    {" "}
                    Maquila de Nómina
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <div className="card-body-sofware">
                <div className="text-center">
                  <OptimizationImg className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center">
                  <Button to={"/software"} width="280">
                    {" "}
                    Desarrollo de software
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Approach;
