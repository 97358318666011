// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

// Assets.
import { LogoWhiteSvg, KatanaLogoPurpleSvg, IconLinkedInSvg } from "./Assets";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container>
          <a href="/">
            <LogoWhiteSvg classAnchor="footer__logo" />
          </a>
          <div className="footer__address">
            <p>KATANA &amp; NOMI, S.A. DE C.V.</p>
            <p className="fw-regular">
              Francisco de Montejo 5, Cd. Satélite, 53100 Naucalpan de Juárez,
              EDOMEX, México.
            </p>
            <p className="telefono">Teléfono: +52 (55) 8970-3370</p>
          </div>
          <div className="footer__copyright">
            &copy; KATANA &amp; NOMI, S.A. de C.V.
          </div>
          <div className="footer__links col1">
            <div className="title">NOSOTROS</div>
            <a href="/the-company">La Empresa</a>
            <a href="/approach">Nuestro Approach</a>
          </div>

          <div className="footer__links col2">
            <div className="title">SERVICIOS</div>
            <a href="/payroll-maquila">Maquila de Nómina</a>
            <a href="/cfdis">Gestión integral de CFDI’s</a>
            <a href="/software">Desarrollo de Software a la Medida</a>

            <div className="title">PRODUCTOS</div>
            <a href="/integrity">iNTEGRITY</a>
            <a href="/my-company">My[COMPANY]</a>
            <a href="/dicoma">DICOMA</a>
          </div>

          <div className="footer__links col3">
            <div className="title">ACCESO</div>
            <a
              href="https://integrity.nomi.mx/"
              target="_blank"
              rel="noreferrer"
            >
              iNTEGRITY
            </a>

            <div className="title unavailable">OTROS IDIOMAS</div>
            <a href="/" className="unavailable">
              English
            </a>
            <a href="/" className="unavailable">
              Deutsche
            </a>
            <a href="/" className="unavailable">
              日本語
            </a>
          </div>

          <div className="footer__links col4">
            <div className="title">CONTACTO</div>
            <a href="/contact">¡Hablemos!</a>

            <div className="title SM">SÍGUENOS</div>
            <a
              href="https://www.linkedin.com/company/nomi-maquila-nomina/mycompany/"
              target="_blank"
              className="SM"
              rel="noreferrer"
            >
              <IconLinkedInSvg />
            </a>

            <div className="title">LEGAL</div>
            <a href="/privacy">Aviso Privacidad</a>
          </div>
          <KatanaLogoPurpleSvg classAnchor="footer__katana-logo" />
        </Container>
      </footer>
      <div className="rectangle"></div>
    </>
  );
};

export default Footer;
