// Librerias.
import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

// Components Base.
import ReturnToServices from "../components-base/ReturnToServices";

// Components Software.
import { TitleUX } from "./Titles";

// Assets.
import userIcon from "./assets/ux/icon-user.svg";
import useCaseIcon from "./assets/ux/icon-use-case.svg";
import flowIcon from "./assets/ux/icon-flows.svg";
import lineIcon from "./assets/ux/icon-line.svg";

const CardUx = ({ title, icon, after, description }) => {
  return (
    <Card>
      <div className="mb-3">
        <img src={icon} alt="icons ux" />
      </div>
      <div>
        <h3 className="mb-3 line-after">{title}</h3>
        <img src={lineIcon} alt="linea" className="mb-3" />
        <p>{description}</p>
      </div>
    </Card>
  );
};
const Ux = () => {
  return (
    <section className="software_ux">
      <Container>
        {/* <img src={toFromIcon} alt="lines" className='lines-to-from img-fluid' /> */}
        <TitleUX />
        <Row className="flex-wrap">
          <Col>
            <CardUx
              title="USUARIO"
              icon={userIcon}
              description="Analizamos y conocemos perfectamente a los usuarios de nuestras aplicaciones, pues están en nuestras instalaciones, son los ejecutivos de cuenta y los conocemos muy bien: sabemos cuáles son sus gustos y aversiones, sus miedos, bloqueos, preocupaciones y objetivos.  Contamos con un buen análisis del usuario y esto nos permite crear productos cien por ciento hechos a la medida de sus necesidades."
            />
          </Col>
          <Col>
            <CardUx
              title="CASOS DE USO"
              icon={useCaseIcon}
              description="Nuestra experiencia sirviendo a empresas del más alto nivel nos proporciona el contacto con casos de uso: se trata de ambientes estresantes en el que se manejan tiempos de entrega ajustados y necesidades inmediatas, problemas específicos, fechas límite, cumplimientos con la legislación vigente, entre muchas otras presiones. Esto nos ha brindado una amplia experiencia a lo largo de muchos años."
            />
          </Col>
          <Col>
            <CardUx
              title="FLUJOS"
              icon={flowIcon}
              description="Los flujos representan el ahorro de pasos y ya se acercan más al diseño. Un flujo puede ser la navegación de las ventanas que se deben visitar para lograr el objetivo de la aplicación. Un diagrama de flujo incluye ventanas o secciones que a su vez se dividen en ventanas de la aplicación y son los diagramas de flujos de esta. Con base en los diagramas de flujo se empieza a diseñar cada una de estas ventanas."
            />
          </Col>
        </Row>
        <ReturnToServices />
      </Container>
      <div className="line" />
    </section>
  );
};
export default Ux;
