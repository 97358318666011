// Librerias.
import React from "react";
// Components Base.
import { Footer, Contact } from "../components-base";
// Components Payroll Maquila.
import Hero from "./Hero";
import Services from "./Services";
import Parameters from "./Parameters";
import Interaction from "./Interaction";
import Incidents from "./Incidents";
import Calculation from "./Calculation";
import SpecialProcesses from "./SpecialProcesses";
import Communication from "./Communication";
import ReportGenerator from "./ReportGenerator";
import Restrospective from "./Restrospective";
import MyCompany from "./MyCompany";

const PagePayrollMaquila = () => {
  return (
    <>
      <div className="sections-payroll-maquila">
        <Hero />
        <Services />
        <Parameters />
        <Interaction />
        <Incidents />
        <Calculation />
        <SpecialProcesses />
        <Communication />
        <MyCompany />
        <ReportGenerator />
        <Restrospective />
        <Contact title="¿Desea hacer más eficiente el flujo de su proceso de nóminas?" />
      </div>
      <Footer />
    </>
  );
};

export default PagePayrollMaquila;
