// Librerias.
import React from "react";

// Components Base.
import { Header } from "../components-base";

// Assets.
import heroBgD from "./assets/img/hero-bg-d.jpg";
import heroD from "./assets/img/hero-d.png";
import heroBgT from "./assets/img/hero-bg-p.jpg";
import heroBgP from "./assets/img/hero-bg-p.jpg";

const Hero = () => {
  return (
    <div className="integrity_full">
      <Header
        uri_bg_d={heroBgD}
        uri_bg_t={heroBgT}
        uri_bg_p={heroBgP}
        className="integrity_hero"
        height={1547}
      >
        <div className="hero-text">
          <h1 className="text-center">Simplicidad, conectividad y poder</h1>
          <div className="d-flex justify-content-center">
            <h1 className="sub-title">Sistema de Nóminas en la nube</h1>
          </div>
        </div>
      </Header>
      <div className="bg">
        <img
          src={heroD}
          alt="Integrity Interface"
          className="bg-img img-fluid"
        />
      </div>
    </div>
  );
};
export default Hero;
