// Librerias.
import React from "react";

import { Card, Container } from "react-bootstrap";
// Components Base.
import { Button } from "../components-base";

// Assets.
import { IconArrowSvg, IconTeamNomiSvg } from "./Assets";

// Hooks.
import useWindow from "../hooks/useWindow";

const OurApproachDesktop = () => {
  return (
    <section className="the-company_our-approach the-company_our-approach__bg">
      <Container className="container__bg">
        <Card style={{ width: "43.125rem" }}>
          <Card.Body>
            <IconTeamNomiSvg className="the-company_our-approach__icon--team-nomi" />
            <Card.Text>
              Somos un equipo excepcional, integrado por un selecto grupo de
              personas que entienden perfectamente que nuestra diferenciación
              radica en:{" "}
              <span>La forma de ver las cosas, de pensar y de actuar…</span>
            </Card.Text>
            <Button to="/approach" width="320">
              Nuestro Approach{" "}
              <span>
                <IconArrowSvg className="icon-arrow"></IconArrowSvg>
              </span>
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

const OurApproachMobile = () => {
  return (
    <section className="the-company_our-approach_mobile the-company_our-approach_mobile___bg">
      <Container className="container__bg">
        <IconTeamNomiSvg className="the-company_our-approach__icon--team-nomi" />
        <Card.Text>
          Somos un equipo excepcional, integrado por un selecto grupo de
          personas que entienden perfectamente que nuestra diferenciación radica
          en: <span>La forma de ver las cosas, de pensar y de actuar…</span>
        </Card.Text>

        <Button to="/approach">
          Nuestro Approach{" "}
          <span>
            <IconArrowSvg className="icon-arrow"></IconArrowSvg>
          </span>
        </Button>
      </Container>
    </section>
  );
};

const OurApproach = () => {
  const windowSize = useWindow();
  return (
    <>
      {windowSize.width > 475 ? <OurApproachDesktop /> : <OurApproachMobile />}
    </>
  );
};

export default OurApproach;
