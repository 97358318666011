// Librerias.
import React from "react";
import { Container, Card, Col, Row } from "react-bootstrap";

// Components Base.
import { Button } from "../components-base";

// Assets.
import talent from "./assets/img/talent.svg";
import sales from "./assets/img/sales.svg";
import support from "./assets/img/support.svg";

const CardForms = ({ icon, title, to }) => {
  return (
    <Card>
      <img
        src={icon}
        alt="icon personalization"
        className="icon-card"
        width={100}
        height={100}
      />
      <h4>{title}</h4>
      <div>
        <Button to={to} max-width="240">
          <div>Ir al Formulario</div>
        </Button>
      </div>
    </Card>
  );
};

const Forms = () => {
  return (
    <section className="contact_forms">
      <Container>
        <h3>Díganos cómo podemos asistirle...</h3>

        <Row className="gap-5">
          <Col>
            <CardForms icon={sales} title={"VENTAS"} to={"/contact/sales"} />
          </Col>
          <Col>
            <CardForms
              icon={support}
              title={"SOPORTE TÉNICO"}
              to={"/contact/support"}
            />
          </Col>
          <Col>
            <CardForms
              icon={talent}
              title={"TRABAJO EN NOMI"}
              to={"/contact/talent"}
            />
          </Col>
        </Row>

        <div className="info-general">
          <h4>Comunicación General</h4>
          <p>
            Para consultas y comentarios sobre temas de índole general, envíe un
            correo a:{" "}
          </p>
          <a href="mailto:hola@nomi.mx" target="_blank" rel="noreferrer">
            hola@nomi.mx
          </a>
        </div>
      </Container>
    </section>
  );
};
export default Forms;
