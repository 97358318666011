// Livrerias
import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

// Componenes Payroll Maquila.
import { TitleParameters } from "./Titles";
import { ReturnToServices } from "../components-base";

// Assets.
import parametersGearSvg from "./assets/img/parameters-gear.svg";
import logoWorkday from "./assets/logos/logo-workday.svg";
import logoSap from "./assets/logos/logo-sap.svg";
import logoPeopleSoft from "./assets/logos/logo-people-soft.svg";

const CardParameters = ({ title, children }) => {
  return (
    <Card className="mt-3">
      <Card.Body>
        <p className="fw-bold">{title}</p>
        <ul className="list-unstyled">{children}</ul>
      </Card.Body>
    </Card>
  );
};
const Parameters = () => {
  return (
    <section className="payroll_maquila_parameters">
      <Container>
        <TitleParameters />
      </Container>
      <div className="payroll_maquila_parameters__border" />
      <Container className=" payroll_maquila_parameters__implementation">
        <h3>1.1 Implementación</h3>
        <div className="d-flex justify-content-between gap-1 flex-lg-nowrap flex-wrap">
          <div>
            <p>
              Al tomar la sabia decisión de trabajar con nomi, nos convertiremos
              en parte de su equipo. Y juntos, definiremos procesos específicos
              de trabajo, cálculo y los mejores métodos para el intercambio de
              información, de acuerdo a la operación y objetivos de su negocio,
              en busca de proveerle la mejor solución personalizada de
              procesamiento de nóminas.
            </p>
            <p>
              De acuerdo a los alcances del servicio y otros factores como:
              frecuencia de pago, cantidad de empleados, tipo y periodicidad de
              reportes… esta fase puede tomar de uno a varios meses.{" "}
              <span className="fw-bold">
                Sin embargo, cabe aclarar dos cosas:
              </span>
            </p>
            <p className="fw-bold">
              1.- Nuestro tiempo de implementación es el más corto del mercado.
            </p>
            <p className="fw-bold">
              2.- NO calculamos mal el tiempo de implementación: garantizado.
            </p>
          </div>
          <div className="img-gear">
            <img
              src={parametersGearSvg}
              alt="people gear"
              className=" "
              width={250}
              height={186}
            />
          </div>
        </div>
        <Row className="justify-content-between mt-5 payroll_maquila_parameters__implementation--card flex-wrap">
          <Col lg={3}>
            <CardParameters title={"a) Project Planning"}>
              <li>- Aprobación de Contrato</li>
              <li>- Kickoff</li>
              <li>- Monitoreo y control del Plan de Implementación</li>
            </CardParameters>
          </Col>
          <Col lg={3}>
            <CardParameters title={"b) Configuración del Sistema"}>
              <li>- Catálogo de conceptos</li>
              <li>- Carga de información de empleados</li>
              <li>- Carga de información histórica de nóminas</li>
              <li>- Interfaces de contabilidad, RRHH y otras</li>
            </CardParameters>
          </Col>
          <Col lg={3}>
            <CardParameters title={"c) Pruebas (QA)"}>
              <li>- Diseño/pruebas formatos de reportes y recibos</li>
              <li>- Nómina en paralelo (simulación)</li>
              <li>
                - Pruebas de auditoría de cálculo y funcionalidad de interfaces
              </li>
            </CardParameters>
          </Col>
          <Col lg={3}>
            <CardParameters title={"d) Inicio de Producción"}>
              <li>- Decisión de arranque sí/no</li>
              <li>- Arranque (monitoreo)</li>
              <li>- Soporte de producción</li>
            </CardParameters>
          </Col>
        </Row>
      </Container>
      <div className="payroll_maquila_parameters__border" />
      <Container className="payroll_maquila_parameters__anual">
        <h3>1.2 Actualización Anual</h3>
        <p>
          Esta información debe actualizarse, por ley, cada año conforme al
          ajuste anual de impuestos y leyes de trabajo. Por ello, nuestra área
          de Cumplimiento legal está siempre actualizada y al tanto sobre
          cualquier cambio (incluso antes de ser aprobado) en temas de:
        </p>
        <div className="mt-4">
          <p>
            <span className="fw-bold">Tablas de Impuestos:</span> Impuesto Sobre
            la Renta (ISR), Impuesto Sobre la Nómina (ISN) y contribuciones al
            Seguro Social.
          </p>
          <p>
            <span className="fw-bold">Prima de Riesgo de Trabajo:</span> Nuevo
            porcentaje de contribución, sobre el total de la nómina, conforme a
            los accidentes reportados durante el año.
          </p>
          <p>
            <span className="fw-bold">Salario Mínimo:</span> Actualización de
            acuerdo al incremento anual.
          </p>
          <p>
            <span className="fw-bold">
              Captura del UMA (Unidad de medida y Actualización):
            </span>{" "}
            para cálculos de nómina y descuentos de INFONAVIT.
          </p>
        </div>
      </Container>
      <Container className="payroll_maquila_parameters__continue">
        <div className="payroll_maquila_parameters__border2" />
        <h3>1.3 Actualización Continua</h3>
        <p className="mb-4">
          Esta es una actualización de su plantilla en nuestro Sistema de
          Nóminas, que considera cualquier cambio en a su información personal,
          nuevas contrataciones, bajas, cambios de puesto, incrementos de
          salario…
        </p>

        <p>
          La interfaz de entrada a nuestro sistema permite la transferencia
          automatizada de datos desde las plataformas más prestigiosas de RRHH.
          Sin embargo, estamos listos para desarrollar nuevas interfaces para
          cualquier otro sistema y/o recibir información como mejor convenga a
          su equipo.
        </p>
        <div className="my-4">
          <img src={logoWorkday} alt="logo workday" className="me-3" />
          <img src={logoPeopleSoft} alt="logo sap" className="me-3" />
          <img src={logoSap} alt="logo people soft" />
        </div>
        <ReturnToServices />
      </Container>
    </section>
  );
};
export default Parameters;
