// Librerias.
import React from "react";
import { Container } from "react-bootstrap";

const What = () => {
  return (
    <section className="page_approach_what">
      <Container>
        <p className='question'>¿Qué nos hace difentes?</p>
        <p className='our'>Nuestra gente.</p>
        <p>
          Y la experiencia de servicio que están tan preparados como
          determinados a proveer a nuestros clientes…
        </p>
      </Container>
    </section>
  );
};

export default What;
