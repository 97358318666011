import React from "react";
import { Container } from "react-bootstrap";
// Componenes CFDI's.
import { TitleDispersion } from "./Titles";
//Asset.
import squemaDispersion from "./assets/img/squemaCFDIS.svg";
import squemaDispersionMobile from "./assets/img/squemaCFDIS-mobile.svg";

const Dispersion = () => {
  return (
    <section className="cfdis_dispersion">
      <Container>
        <TitleDispersion />
        <img
          src={squemaDispersion}
          alt="gsn nomi"
          width={1080}
          height={460}
          className="img-fluid img-desktop"
        />
        <img
          src={squemaDispersionMobile}
          alt="gsn nomi"
          width={1080}
          height={460}
          className="img-fluid img-mobile"
        />
      </Container>
    </section>
  );
};
export default Dispersion;
