// Librerias.
import React from "react";

// Assets.
import dispersion from "./assets/icons/dispersion-section.svg";
import iconGuard from "./assets/icons/guard-section.svg";
import personalization from "./assets/icons/personalization.svg";

const Title = ({ icon, title, id, children }) => {
  return (
    <div className="cfdis_title" id={id}>
      <div className="d-flex flex-sm-row flex-column align-items-center mb-4">
        <img src={icon} alt="" width={50} height={50} />
        <h2 className="ms-0 ms-sm-3 my-3 my-sm-0">{title}</h2>
      </div>
      <p className="cfdis_description">{children}</p>
    </div>
  );
};

export const TitlePersonalization = () => {
  return (
    <Title
      icon={personalization}
      title="PERSONALIZACIÓN"
      id="personalization"
      children={
        <>
          Podemos diseñar el recibo de nóminas ideal, con la información y
          elementos que se adapten a las necesidades específicas de su
          organización. Esto con el objetivo de brindar mayor claridad a sus
          empleados y reducir la cantidad de aclaraciones que solicitan a su
          área de nóminas o RRHH.
          <p className="fw-bold">
            Layout: Definimos la información, además de la indispensable y
            obligatoria por ley, que integrará el recibo.
          </p>
          <p className="fw-bold">
            Branding: Adaptamos sus estándares de identidad gráfica corporativa
            al diseño del PDF.
          </p>
          <p className="fw-bold">
            Information: Ajustes en el sistema de nóminas y en el xml para
            obtener la información que integra el recibo.
          </p>
        </>
      }
    />
  );
};
export const TitleDispersion = () => {
  return (
    <Title
      icon={dispersion}
      title="DISPERSIÓN"
      id="dispersion"
      children={
        <>
          Una vez que el cálculo de la nómina es correcto y que esta se autoriza
          y se paga a los empleados, se lleva a cabo el proceso de certificación
          (timbrado) y generación del CFDI, el cual se representa en dos tipos
          de archivos: XML y PDF (representación visual) y ambos son enviados
          vía correo al empleado, así como almacenado para su consulta
          histórica.
        </>
      }
    />
  );
};
export const TitleGuard = () => {
  return (
    <Title
      icon={iconGuard}
      title="RESGUARDO"
      id="guard"
      children={
        <>
          Almacenamos todos los CFDI’s generados nómina tras nómina en nuestra
          bóveda fiscal, a la cual tienen acceso los integrantes de su área de
          RRHH o nóminas y en la que pueden gestionar los archivos conforme a
          sus necesidades. De igual forma, los empleados tienen acceso a
          consultar su histórico de recibos en nuestra aplicación de
          auto-servicio.
        </>
      }
    />
  );
};
